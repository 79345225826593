import { FileUpload, UploadType } from "../common";
import "./preapproval.css";
import { useNavigateWithFlowIdURLParam } from "../../../BuyingFlow";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  userEmailVerifiedRequirement,
  userPhoneVerifiedRequirement,
  userSignedInRequirement,
  withRequirements,
} from "../../../../../requirements";
import { BuyingFlow } from "../../../../../../model/Core/BuyingFlow";
import {
  getCurrentUserBuyingFlow,
  setBuyingFlowPreApproval,
} from "../../../../../../APIs/buyingflow";
import {
  deleteUserPreApproval,
  getUserPreApprovals,
} from "../../../../../../APIs/budgeting";
import { PreApprovalFileResponse } from "../../../../../../model/Core/Files";
import DropDown from "../../../../../../assets/drop_down.svg";
import BringUp from "../../../../../../assets/bring_up.svg";
import Trash from "../../../../../../assets/delete.svg";
import { UUIDTypes } from "uuid";

const BudgetingPreApproval: React.FC = () => {
  /* 
  ================================================================
  CONSTANTS
  ================================================================
  */
  const [searchParams] = useSearchParams();
  const flowId = searchParams.get("flow")?.replace(/^"|"$/g, "");
  const navigateWithFlowId = useNavigateWithFlowIdURLParam();
  const navigate = useNavigate();

  /* 
  ================================================================
  STATE VARIABLES
  ================================================================
  */
  const [fileSelected, setFileSelected] = useState<boolean>(false);
  const [refreshKey, setRefreshKey] = useState<number>(0);

  /* 
  ================================================================
  HOOK: Ensure valid flow 
  ================================================================
  */
  useEffect(() => {
    const validateFlow = async () => {
      if (flowId == null) {
        navigate("/buyingflow/none-selected");
        return;
      }

      let flowData: BuyingFlow | null = await getCurrentUserBuyingFlow(flowId);
      if (flowData === null) {
        navigateWithFlowId("/buyingflow/flow-nonexistent");
        return;
      }
    };

    validateFlow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* 
  ================================================================
  NAVIGATION
  ================================================================
  */
  const handleEnterCashClick = () => {
    navigateWithFlowId("/buyingflow/budgeting/enter-cash");
  };
  const handleEnterContinue = () => {
    navigateWithFlowId("/buyingflow/budgeting/enter-proof");
  };

  /* 
  ================================================================
  RENDER
  ================================================================
  */
  return (
    <div className="pre-approval">
      {/* Introduction Section */}
      <section className="pre-approval__intro">
        <p className="pre-approval__title">
          Upload your Pre-Approval Letter(s):
        </p>
      </section>

      {/* Upload Section */}
      <section className="pre-approval__upload-section">
        <FileUpload
          type={UploadType.PRE_APPROVAL_DATA}
          onUploadComplete={() => setRefreshKey((prevKey) => prevKey + 1)}
        />
      </section>

      {/* List of Uploaded Pre Approvals */}
      <section className="pre-approval__files-analysis">
        <UserPreApprovals
          setSelectedFileBoolean={setFileSelected}
          refreshKey={refreshKey}
        />
      </section>

      {/* Call to Action */}
      <section className="pre-approval__cta">
        <button
          className="pre-approval__cta-button"
          onClick={handleEnterContinue}
          disabled={!fileSelected}
        >
          Continue
        </button>
      </section>

      {/* Enter Cash */}
      <section className="pre-approval__enter-cash">
        <div
          className="pre-approval__enter-cash-text"
          onClick={handleEnterCashClick}
        >
          Buying in all cash? Click here to continue.{" "}
        </div>
      </section>
    </div>
  );
};

/* 
================================================================
User Pre-Approval List
================================================================
*/
interface UserPreApprovalsProps {
  setSelectedFileBoolean: React.Dispatch<React.SetStateAction<boolean>>;
  refreshKey: number;
}

const UserPreApprovals: React.FC<UserPreApprovalsProps> = ({
  setSelectedFileBoolean,
  refreshKey,
}) => {
  /* 
  ================================================================
  CONSTANTS
  ================================================================
  */
  const [searchParams] = useSearchParams();
  const flowId = searchParams.get("flow");

  /* 
  ================================================================
  STATE VARIABLES
  ================================================================
  */
  const [files, setFiles] = useState<PreApprovalFileResponse[]>([]);
  const [expandedFiles, setExpandedFiles] = useState<boolean[]>([]);
  const [selectedFile, setSelectedFile] =
    useState<PreApprovalFileResponse | null>(null);

  /* 
  ================================================================
  HOOKS: misc
  ================================================================
  */
  useEffect(() => {
    fetchPreApprovals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey]);

  useEffect(() => {
    setSelectedFileBoolean(true ? selectedFile !== null : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  /* 
  ================================================================
  HOOK: Toogle selected file on backend
  ================================================================
  */
  const handleCheckboxChange = async (preApprovalFileId: UUIDTypes) => {
    if (flowId === null) {
      alert("Not in a valid buying flow. Go to dashboard.");
      return;
    }

    // Update buying flow backend
    try {
      await setBuyingFlowPreApproval(flowId, preApprovalFileId);
    } catch (error) {
      alert(error);
      return;
    }

    // Set the selected file in the UI (deselect if same)
    const buyingFlow = await getCurrentUserBuyingFlow(flowId);
    if (buyingFlow?.budgeting.pre_approval_file_id !== null) {
      const newSelectedFile = files.find(
        (file) => file.id === buyingFlow?.budgeting.pre_approval_file_id
      );
      setSelectedFile(newSelectedFile || null);
    } else {
      setSelectedFile(null);
    }
  };

  /* 
  ================================================================
  FUNCTION: Fetch pre approvals
  ================================================================
  */
  const fetchPreApprovals = async () => {
    try {
      const files = await getUserPreApprovals();
      setExpandedFiles(new Array(files.length).fill(false));

      // Look at buying flow data to see which file is currently selected
      if (flowId) {
        const buyingFlow = await getCurrentUserBuyingFlow(flowId);
        if (buyingFlow?.budgeting.pre_approval_file_id !== null) {
          const foundSelectedFile = files.find(
            (file) => file.id === buyingFlow?.budgeting.pre_approval_file_id
          );
          if (foundSelectedFile) {
            setSelectedFile(foundSelectedFile);
          }
        }
      }
      setFiles(files);
    } catch (error) {
      console.error("Error fetching pre-approval data", error);
    }
  };

  /* 
  ================================================================
  FUNC: Handle file deletion
  ================================================================
  */
  const handleFileDeletion = async (fileId: UUIDTypes) => {
    try {
      await deleteUserPreApproval(fileId);
    } catch (error) {
      alert(error);
      return;
    }
    fetchPreApprovals();
  };

  /* 
  ================================================================
  FUNC: Toogle file expansion
  ================================================================
  */
  const toggleFileExpansion = (index: number) => {
    setExpandedFiles((prev) => {
      const newExpandedFiles = [...prev];
      newExpandedFiles[index] = !newExpandedFiles[index];
      return newExpandedFiles;
    });
  };

  /* 
  ================================================================
  RENDER
  ================================================================
  */
  return (
    <div
      className={`pre-approval-meta-data ${
        files.length === 0
          ? "pre-approval-meta-data--not-shown"
          : "pre-approval-meta-data--shown "
      }`}
    >
      {files.map((file, index) => (
        <div key={index} className="pre-approval-meta-data__container">
          <div className="pre-approval-meta-data__header">
            <input
              type="checkbox"
              readOnly
              checked={selectedFile?.id === file.id}
              onClick={() => handleCheckboxChange(file.id)}
            />

            <div
              className="pre-approval-meta-data__toggle"
              onClick={() => toggleFileExpansion(index)}
            >
              <img
                src={expandedFiles[index] ? BringUp : DropDown}
                alt="toggle"
              />
            </div>

            <p className="pre-approval-meta-data__file-name">{file.filename}</p>

            <div
              className="pre-approval-meta-data__delete"
              onClick={() => handleFileDeletion(file.id)}
            >
              <img src={Trash} alt="delete" />
            </div>
          </div>
          {expandedFiles[index] && (
            <div className="pre-approval-meta-data__data-container">
              <div className="pre-approval-meta-data__info">
                <p className="pre-approval-meta-data__info-key">Loan Amount:</p>
                <p className="pre-approval-meta-data__info-value">
                  {file.loan_amount}
                </p>
              </div>
              <div className="pre-approval-meta-data__info">
                <p className="pre-approval-meta-data__info-key">Lender:</p>
                <p className="pre-approval-meta-data__info-value">
                  {file.lending_company}
                </p>
              </div>
              <div className="pre-approval-meta-data__info">
                <p className="pre-approval-meta-data__info-key">Expiration:</p>
                <p className="pre-approval-meta-data__info-value">
                  {file.expiration && file.expiration.toISOString()}
                </p>
              </div>
              <div className="pre-approval-meta-data__info">
                <p className="pre-approval-meta-data__info-key">
                  Lender Agent Name:
                </p>
                <p className="pre-approval-meta-data__info-value">
                  {file.lending_agent?.name}
                </p>
              </div>
              <div className="pre-approval-meta-data__info">
                <p className="pre-approval-meta-data__info-key">
                  Lender Agent Email:
                </p>
                <p className="pre-approval-meta-data__info-value">
                  {file.lending_agent?.email}
                </p>
              </div>
              <div className="pre-approval-meta-data__info">
                <p className="pre-approval-meta-data__info-key">
                  Lender Agent Cell:
                </p>
                <p className="pre-approval-meta-data__info-value">
                  {file.lending_agent?.phone}
                </p>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default withRequirements(BudgetingPreApproval, [
  userSignedInRequirement,
  userEmailVerifiedRequirement,
  userPhoneVerifiedRequirement,
]);
