import { BUYING_FLOW_STEP, buyingFlowStepTitles } from "../StepComponents";
import "./layout.css";

interface DashboardTitleProps {
  step: BUYING_FLOW_STEP;
  address: string | null;
}

const DashboardTitle: React.FC<DashboardTitleProps> = ({ step, address }) => {
  const stepTitle = buyingFlowStepTitles[step];

  return (
    <div className="dashboard-component-header">
      <h2 className="dashboard-component-header__title">{stepTitle}</h2>

      {step !== BUYING_FLOW_STEP.HOME_SEARCH && (
        <p className="dashboard-component-header__address">{address}</p>
      )}
    </div>
  );
};

const DashboardProgress: React.FC = () => {
  return <div>PROGRESSBAR PLACEHOLDER</div>;
};

export { DashboardTitle, DashboardProgress };
