import { useEffect, useRef, useState } from "react";
import { Home } from "../../../../../model/Core/Home";

import "./home_search.css";
import {
  getCurrentUser,
  postWithAuth,
} from "../../../../../firebase/authentication";
import { useNavigate } from "react-router-dom";
import { User } from "firebase/auth";

const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";
const AGNSY_SERVER_ADDRESS = process.env.REACT_APP_AGNSY_SERVER_ADDRESS;
const HOMES_PER_PAGE = 20;

interface HomeWithCoverPhoto extends Home {
  coverPhotoBase64?: string;
  isLoadingPhoto?: boolean;
}

interface HomePhoto {
  id: string;
  photoBase64: string;
}

const HomeSearch = () => {
  const [query, setQuery] = useState("");
  const [homes, setHomes] = useState<HomeWithCoverPhoto[]>([]);
  const [filteredHomes, setFilteredHomes] = useState<HomeWithCoverPhoto[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [offerLoading, setOfferLoading] = useState(false);

  // New state for selected home and photos
  const [selectedHome, setSelectedHome] = useState<HomeWithCoverPhoto | null>(
    null
  );
  const [homePhotos, setHomePhotos] = useState<HomePhoto[]>([]);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [loadingPhotos, setLoadingPhotos] = useState(false);

  const navigate = useNavigate();
  const listRef = useRef<HTMLUListElement>(null);

  const scrollToTop = () => {
    if (listRef.current != null) {
      listRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  };

  useEffect(() => {
    const fetchHomes = async () => {
      try {
        const response = await fetch(
          `${HTTP_PROTOCAL}://${AGNSY_SERVER_ADDRESS}/api/homes/all/LISTED`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch homes");
        }
        const data: Home[] = await response.json();
        setHomes(data);
        setFilteredHomes(data);

        // Set the first home as selected once data is loaded
        if (data.length > 0) {
          setSelectedHome(data[0] as HomeWithCoverPhoto);
        }
      } catch (error) {
        console.error("Error fetching homes:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchHomes();
  }, []);

  // Fetch photos for selected home
  useEffect(() => {
    if (!selectedHome) return;

    const fetchHomePhotos = async () => {
      setLoadingPhotos(true);
      try {
        const response = await fetch(
          `${HTTP_PROTOCAL}://${AGNSY_SERVER_ADDRESS}/api/homes/${selectedHome.id}/photos`
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch photos for home ${selectedHome.id}`);
        }

        // Assuming the response is an array of base64 strings
        const photoData = await response.json();

        // Transform the data into our HomePhoto format
        const photos = Array.isArray(photoData)
          ? photoData.map((photo, index) => ({
              id: `photo-${index}`,
              photoBase64:
                typeof photo === "string"
                  ? photo.replace(/^["']|["']$/g, "")
                  : photo,
            }))
          : [];

        setHomePhotos(photos);
        setCurrentPhotoIndex(0);
      } catch (error) {
        console.error(
          `Error fetching photos for home ${selectedHome.id}:`,
          error
        );
        setHomePhotos([]);
      } finally {
        setLoadingPhotos(false);
      }
    };

    fetchHomePhotos();
  }, [selectedHome]);

  // Fetch cover photos for the current page homes
  useEffect(() => {
    if (loading) return;

    const startIdx = currentPage * HOMES_PER_PAGE;
    const paginatedHomes = filteredHomes.slice(
      startIdx,
      startIdx + HOMES_PER_PAGE
    );

    // Only fetch photos for homes that don't already have them
    const homesNeedingPhotos = paginatedHomes.filter(
      (home) => !home.coverPhotoBase64 && !home.isLoadingPhoto
    );

    if (homesNeedingPhotos.length === 0) return;

    // Fetch cover photos for each home on the current page
    const fetchCoverPhotos = async () => {
      const updatedHomes = [...filteredHomes];

      // Mark homes on current page as loading photos
      homesNeedingPhotos.forEach((home) => {
        const index = updatedHomes.findIndex((h) => h.id === home.id);
        if (index !== -1) {
          updatedHomes[index] = {
            ...updatedHomes[index],
            isLoadingPhoto: true,
          };
        }
      });
      setFilteredHomes(updatedHomes);

      // Fetch each photo
      const photoPromises = homesNeedingPhotos.map(async (home) => {
        try {
          const response = await fetch(
            `${HTTP_PROTOCAL}://${AGNSY_SERVER_ADDRESS}/api/homes/${home.id}/cover-photo`
          );

          if (!response.ok) {
            throw new Error(`Failed to fetch cover photo for home ${home.id}`);
          }

          // The response is a base64 string - remove any quotes that might be present
          const base64Data = await response.text();
          const cleanBase64 = base64Data.replace(/^["']|["']$/g, "");

          return {
            id: home.id,
            coverPhotoBase64: cleanBase64,
            isLoadingPhoto: false,
          };
        } catch (error) {
          console.error(
            `Error fetching cover photo for home ${home.id}:`,
            error
          );
          return {
            id: home.id,
            coverPhotoBase64: undefined,
            isLoadingPhoto: false,
          };
        }
      });

      const results = await Promise.all(photoPromises);

      // Update homes with cover photos
      const finalUpdatedHomes = [...updatedHomes];
      results.forEach((result) => {
        const index = finalUpdatedHomes.findIndex((h) => h.id === result.id);
        if (index !== -1) {
          finalUpdatedHomes[index] = {
            ...finalUpdatedHomes[index],
            coverPhotoBase64: result.coverPhotoBase64,
            isLoadingPhoto: false,
          };
        }
      });

      // Also update the main homes array
      const updatedAllHomes = [...homes];
      results.forEach((result) => {
        const index = updatedAllHomes.findIndex((h) => h.id === result.id);
        if (index !== -1) {
          updatedAllHomes[index] = {
            ...updatedAllHomes[index],
            coverPhotoBase64: result.coverPhotoBase64,
            isLoadingPhoto: false,
          };
        }
      });
      setFilteredHomes(finalUpdatedHomes);
      setHomes(updatedAllHomes);
    };

    fetchCoverPhotos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, filteredHomes.length, loading]);

  useEffect(() => {
    scrollToTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (!query) {
      setFilteredHomes(homes);
      setCurrentPage(0);
      return;
    }

    const lowerQuery = query.toLowerCase();
    setFilteredHomes(
      homes.filter(
        (home) =>
          home.address.address_line_1.toLowerCase().includes(lowerQuery) ||
          home.address.city.toLowerCase().includes(lowerQuery)
      )
    );
    setCurrentPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  // Get homes for the current page
  const startIdx = currentPage * HOMES_PER_PAGE;
  const paginatedHomes = filteredHomes.slice(
    startIdx,
    startIdx + HOMES_PER_PAGE
  );

  // Photo navigation handlers
  const goToNextPhoto = () => {
    if (homePhotos.length > 0) {
      setCurrentPhotoIndex((prevIndex) =>
        prevIndex === homePhotos.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  const goToPrevPhoto = () => {
    if (homePhotos.length > 0) {
      setCurrentPhotoIndex((prevIndex) =>
        prevIndex === 0 ? homePhotos.length - 1 : prevIndex - 1
      );
    }
  };

  const handleStartOffer = async () => {
    let userPresent: User | null = await getCurrentUser();
    if (userPresent === null) {
      navigate("/login");
      return;
    }

    if (!selectedHome) {
      alert("Please select a home to start an offer.");
      return;
    }

    setOfferLoading(true);
    try {
      const formData = new FormData();
      formData.append("home_id", selectedHome.id as string);
      const response = await postWithAuth(
        `${HTTP_PROTOCAL}://${AGNSY_SERVER_ADDRESS}/api/buying-flow/start`,
        formData
      );

      if (!response.ok) {
        if (response.status === 404) {
          alert("Invalid Home ID. Please select a valid home.");
        } else if (response.status === 401) {
          alert("Unauthorized. Please log in to start an offer.");
        } else {
          alert("An unexpected error occurred. Please try again.");
        }
        return;
      }

      const buyingFlowId = await response.text();
      await new Promise((resolve) => setTimeout(resolve, 4000));
      navigate(
        `/buyingflow/budgeting?flow=${buyingFlowId.replace(/^"|"$/g, "")}`
      );
    } catch (error) {
      console.error("Error starting offer:", error);
      alert("Failed to start offer. Please try again.");
    } finally {
      setOfferLoading(false);
    }
  };

  return (
    <div className="home-search">
      <input
        type="text"
        placeholder="Search by address or city..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        className="home-search-input"
      />
      <span className="home-search-results-number">
        {filteredHomes.length} results
      </span>
      <div className="home-search-container">
        {/* Left side - Home list */}
        <div className="home-search-main-content">
          {loading ? <p>Loading Data...</p> : null}
          <ul
            className={`home-search-list ${loading ? "loading" : ""}`}
            ref={listRef}
          >
            {loading
              ? Array.from({ length: 5 }).map((_, index) => (
                  <li key={index} className="home-search-card skeleton">
                    <div className="home-search-card-image"></div>
                    <div className="home-search-card-details">
                      <div className="home-search-card-price"></div>
                      <div className="home-search-card-info"></div>
                      <div className="home-search-card-address"></div>
                    </div>
                  </li>
                ))
              : paginatedHomes.map((home) => (
                  <li
                    key={home.id as string}
                    className={`home-search-card ${
                      selectedHome?.id === home.id ? "selected" : ""
                    }`}
                    onClick={() => setSelectedHome(home)}
                  >
                    {/* Image with loading state */}
                    <div>
                      {home.isLoadingPhoto ? (
                        <div className="home-search-card-image-loading"></div>
                      ) : home.coverPhotoBase64 ? (
                        <img
                          src={`data:image/jpeg;base64,${home.coverPhotoBase64}`}
                          alt={`${home.address.address_line_1}`}
                          className="home-search-card-image"
                        />
                      ) : (
                        <div className="home-search-card-image-loading"></div>
                      )}
                    </div>

                    {/* Details Section */}
                    <div className="home-search-card-details">
                      <div className="home-search-card-price">
                        ${home.listed_price?.toLocaleString()}
                        <span className="home-search-card-rebate">
                          Agnsy Rebate:{" "}
                          <b>
                            $
                            {home.listed_price != null
                              ? Math.round(
                                  home.listed_price * 0.025 * 0.85
                                ).toLocaleString()
                              : "N/A"}{" "}
                          </b>
                        </span>
                      </div>

                      <div className="home-search-card-info">
                        <b>{home.bedrooms ?? "--"}</b> bd |{" "}
                        <b>{home.bathrooms ?? "--"}</b> ba |{" "}
                        {home.building_sqft ?? "--"} sqft
                      </div>

                      <div className="home-search-card-address">
                        {home.address.address_line_1}
                        {home.address.address_line_2
                          ? `, ${home.address.address_line_2}`
                          : ""}
                        , {home.address.city}, {home.address.zip_code}
                      </div>
                    </div>
                  </li>
                ))}
          </ul>
          {/* Pagination Controls */}
          {!loading && (
            <div className="home-search-pagination">
              <button
                disabled={currentPage === 0}
                onClick={() => {
                  setCurrentPage((prev) => prev - 1);
                }}
              >
                Prev
              </button>
              <span>Page {currentPage + 1}</span>
              <button
                disabled={startIdx + HOMES_PER_PAGE >= filteredHomes.length}
                onClick={() => {
                  setCurrentPage((prev) => prev + 1);
                }}
              >
                Next
              </button>
            </div>
          )}
        </div>

        {/* Right side - Selected home details and photos */}
        {selectedHome && (
          <div className="home-search-detail">
            {/* Selected home info banner */}
            <div className="home-search-detail-header">
              <div className="home-search-detail-price">
                ${selectedHome.listed_price?.toLocaleString()}
              </div>
              <div className="home-search-detail-info">
                <b>{selectedHome.bedrooms ?? "--"}</b> bd |{" "}
                <b>{selectedHome.bathrooms ?? "--"}</b> ba |{" "}
                {selectedHome.building_sqft ?? "--"} sqft
              </div>
              <div className="home-search-detail-address">
                {selectedHome.address.address_line_1}
                {selectedHome.address.address_line_2
                  ? `, ${selectedHome.address.address_line_2}`
                  : ""}
                , {selectedHome.address.city}, {selectedHome.address.zip_code}
              </div>
            </div>

            {/* Photo viewer */}
            <div className="home-search-photo-viewer">
              {loadingPhotos ? (
                <div className="home-search-photo-loading">
                  Loading photos...
                </div>
              ) : homePhotos.length > 0 ? (
                <>
                  <button
                    className="home-search-photo-nav prev"
                    onClick={goToPrevPhoto}
                    aria-label="Previous photo"
                  >
                    &lt;
                  </button>
                  <img
                    src={`data:image/jpeg;base64,${homePhotos[currentPhotoIndex].photoBase64}`}
                    alt={`${currentPhotoIndex + 1} of ${
                      selectedHome.address.address_line_1
                    }`}
                    className="home-search-photo"
                  />
                  <button
                    className="home-search-photo-nav next"
                    onClick={goToNextPhoto}
                    aria-label="Next photo"
                  >
                    &gt;
                  </button>
                  <div className="home-search-photo-counter">
                    {currentPhotoIndex + 1} / {homePhotos.length}
                  </div>
                </>
              ) : (
                <div className="home-search-no-photos">No photos available</div>
              )}
            </div>

            {/* Action buttons */}
            <div className="home-search-action-buttons">
              <button
                className="home-search-tour-btn"
                onClick={() => alert("Not yet supported for MVP.")}
              >
                Request Tour
              </button>
              <button
                className={`home-search-offer-btn ${
                  offerLoading ? "loading" : ""
                }`}
                onClick={handleStartOffer}
                disabled={offerLoading}
              >
                {offerLoading ? "Loading..." : "Start Offer"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeSearch;
