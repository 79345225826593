/* eslint-disable react-hooks/exhaustive-deps */
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import DashboardAvatar from "../../../../../assets/kia_avatar.svg";
import Chat from "../../../../../assets/chat.svg";
import "./OfferNegotiation.css";

// Import components
import OfferNegotiationOverview from "./Overview/Overview";
import OfferNegotiationDisclosureAnalysis from "./DisclosureAnalysis/DisclosureAnalysis";
import OfferNegotiationComparableHomes from "./ComparableHomes/ComparableSalesAnalysis";
import OfferNegotiationReconnaissance from "./Reconnaissance/Reconnaissance";
import { useEffect, useState } from "react";
import { User } from "../../../../../model/Core/User";
import { fetchCurrentUserData } from "../../../../../APIs/user";
import { BuyingFlow } from "../../../../../model/Core/BuyingFlow";
import OfferWriting from "./OfferWriting/OfferWriting";
import PriceDeterminationStep from "./PriceDetermination/PriceDetermination";
import {
  userEmailVerifiedRequirement,
  userPhoneVerifiedRequirement,
  userSignedInRequirement,
  withRequirements,
} from "../../../../requirements";
import { budgetingIncomplete } from "../../status";
import { TOOLS, useToolContext } from "../../Tools/ToolViewerContext";
import AIChatViewer from "../../Tools/AIChat/AIChat";
import AIOfferWriter from "../../Tools/AIOfferWriter/AIOfferWriter";
import AIDisclosureAnalysis from "../../Tools/AIDisclosureAnalysis/AIDisclosureAnalysis";
import AIComparableSale from "../../Tools/AIComparableSale/AICompSale";

function OfferNegotiation() {
  /* 
  ================================================================
  CONSTANTS
  ================================================================
  */
  const [searchParams] = useSearchParams();
  const flowId = searchParams.get("flow")?.replace(/^"|"$/g, "");
  const flowIdUrlParameter = flowId ? `?flow=${flowId}` : "";

  /* 
  ================================================================
  STATE VARIABLES
  ================================================================
    */
  const [dynamicallyDeterminedStep, setDynamicallyDeterminedStep] = useState<
    string | null
  >(null);
  const { openTool, isToolOpen, currentTool } = useToolContext();

  /* 
  ================================================================
  HOOK: DETERMINE OFFER/NEGOTIATION STEP
  ================================================================
  */
  useEffect(() => {
    const fetchDataAndDetermineStep = async () => {
      let userData: User | null = await fetchCurrentUserData();
      let buyingFlowData: BuyingFlow | null = null;

      if (flowId && userData !== null) {
        buyingFlowData =
          userData.buying_flows.find((flow) => flow.id === flowId) || null;
      }

      const determinedStep = determineOfferNegotiationStep(
        userData,
        buyingFlowData
      );
      setDynamicallyDeterminedStep(determinedStep);
    };

    fetchDataAndDetermineStep();
  }, []);

  const determineOfferNegotiationStep = (
    userData: User | null,
    buyingFlowData: BuyingFlow | null
  ): string => {
    /*
     * Validity checks
     */
    // No user data, go to dashboard and let it handle it lol
    if (userData == null) {
      return "/dashboard";
    }
    // If there is a flowId url param, but it yields no data, say not existent
    if (flowId != null && buyingFlowData == null) {
      return "/buyingflow/flow-nonexistent" + flowIdUrlParameter;
    }
    // When no flowId specified (no flow data), just start them at Home Search
    if (/*flowId == null && */ buyingFlowData == null) {
      return "/buyingflow/none-selected";
    }

    /*
     * Offer/Negotiation Step Determintation
     */
    // /buyingflow/offer-negotiation/overview
    // /buyingflow/offer-negotiation/disclosure-analysis
    // /buyingflow/offer-negotiation/comparable-homes
    // /buyingflow/offer-negotiation/reconnaissance
    // /buyingflow/offer-negotiation/price-determination

    // TODO: should check for budgeting requirements, to ensure we can even be on negotiation
    //       should go to a "Finish bugeting" page or something
    if (budgetingIncomplete(buyingFlowData)) {
      return "/buyingflow/budgeting/incomplete" + flowIdUrlParameter;
    }

    // TODO: go to /overview if disclosure analysis has not yet started, need to add status first

    /*
     * Check if disclosure-analysis is incomplete
     */
    if (
      buyingFlowData.offer_negotiation.disclosure_analysis_acknowledgement ===
      null // Disclosure analysis not finished
    ) {
      return (
        "/buyingflow/offer-negotiation/disclosure-analysis" + flowIdUrlParameter
      );
    }

    /*
     * Check if comparable-sales-analysis is incomplete
     */
    if (
      buyingFlowData.offer_negotiation
        .comparable_sales_analysis_acknowledgement === null // Comp analysis not finished
    ) {
      return (
        "/buyingflow/offer-negotiation/comparable-homes" + flowIdUrlParameter
      );
    }

    /*
     * TODO: Check if reconnaissance is incomplete (once its in the model / workflow)
     */

    /*
     * Check if user hasn't chosen price
     */
    if (buyingFlowData.offer_negotiation.chosen_price === null) {
      return (
        "/buyingflow/offer-negotiation/price-determination" + flowIdUrlParameter
      );
    }

    // If all checks passed, go to Closing
    return "/buyingflow/closing" + flowIdUrlParameter;
  };

  /* 
  ================================================================
  RENDER: DISPLAY LOADING UNTIL STEP IS DETERMINED
  ================================================================
  */
  if (dynamicallyDeterminedStep == null) {
    return (
      <div className="loading-spinner">
        <div className="loading-spinner__outer" />
        <div className="loading-spinner-text">Loading...</div>
      </div>
    );
  }

  /* 
  ================================================================
  RENDER: ROUTING
  ================================================================
  */
  return (
    <div className="offer-negotiation">
      {isToolOpen && currentTool === TOOLS.AI_CHAT && <AIChatViewer />}
      {isToolOpen && currentTool === TOOLS.OFFER_WRITIER && <AIOfferWriter />}
      {isToolOpen && currentTool === TOOLS.DISCLOSURE_ANALYSIS && (
        <AIDisclosureAnalysis />
      )}
      {isToolOpen && currentTool === TOOLS.COMPARABLE_SALE && (
        <AIComparableSale />
      )}

      <div className="offer-negotiation__header">
        <img
          className="offer-negotiation__avatar"
          src={DashboardAvatar}
          alt="Kia"
        />

        <div
          className="offer-negotiation__chat-icon-container"
          onClick={() => openTool(TOOLS.AI_CHAT)}
        >
          <img className="offer-negotiation__chat-icon" src={Chat} alt="chat" />
        </div>
      </div>

      <Routes>
        {/* Index behaviour: choose where to navigate to based on ?user and ?buyingflow data */}
        <Route
          index
          element={<Navigate to={dynamicallyDeterminedStep} replace />}
        />

        {/* Offer/Negotiation Substeps */}
        <Route path="overview" element={<OfferNegotiationOverview />} />
        <Route
          path="disclosure-analysis"
          element={<OfferNegotiationDisclosureAnalysis />}
        />
        <Route
          path="comparable-homes"
          element={<OfferNegotiationComparableHomes />}
        />
        <Route
          path="reconnaissance"
          element={<OfferNegotiationReconnaissance />}
        />
        <Route path="offer-writing" element={<OfferWriting />} />
        <Route
          path="price-determination"
          element={<PriceDeterminationStep />}
        />
      </Routes>
    </div>
  );
}

export default withRequirements(OfferNegotiation, [
  userSignedInRequirement,
  userEmailVerifiedRequirement,
  userPhoneVerifiedRequirement,
]);
