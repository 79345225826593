import React, { useState } from "react";
import AISparkles from "../../../../../../assets/ai_sparkles.svg";
import EmailAIView from "./EmailAIView";

import "./EmailPreview.css";

interface EmailPreviewProps {
  to: string;
  from: string;
  subject: string;
  body: string[];
  signature: string;
}

const EmailPreview: React.FC<EmailPreviewProps> = ({
  to,
  from,
  subject,
  body,
  signature,
}) => {
  const [showCustomize, setShowCustomize] = useState(false);
  const [isAIViewOpen, setIsAIViewOpen] = useState(false);

  const handleCustomizeClick = () => {
    setIsAIViewOpen(true);
    setShowCustomize(false);
  };

  return (
    <div
      className="email-preview"
      onMouseEnter={() => setShowCustomize(true)}
      onMouseLeave={() => setShowCustomize(false)}
    >
      <div
        className={`email-preview__content ${
          showCustomize ? "email-preview__content--blurred" : ""
        }`}
      >
        <div className="email-preview__field">
          <div className="email-preview__label">To</div>
          <div className="email-preview__value">{to}</div>
        </div>

        <div className="email-preview__field">
          <div className="email-preview__label">From</div>
          <div className="email-preview__value">{from}</div>
        </div>

        <div className="email-preview__divider"></div>

        <div className="email-preview__field">
          <div className="email-preview__label">Subject</div>
          <div className="email-preview__value">{subject}</div>
        </div>

        <div className="email-preview__body">
          {body.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
          <p className="email-preview__signature">
            {signature.split("\n").map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </p>
        </div>
      </div>

      {showCustomize && !isAIViewOpen && (
        <div className="email-preview__customize-overlay">
          <button
            className="email-preview__customize-btn"
            onClick={handleCustomizeClick}
          >
            <img
              className="email-preview__customize-image"
              alt="Customize email"
              src={AISparkles}
            />
            Customize
          </button>
        </div>
      )}

      {isAIViewOpen && (
        <EmailAIView
          isOpen={isAIViewOpen}
          onClose={() => setIsAIViewOpen(false)}
          emailData={{ to, from, subject, body, signature }}
        />
      )}
    </div>
  );
};

export default EmailPreview;
