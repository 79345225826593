/* Data Models */
import { BuyingFlow } from "../../../../model/Core/BuyingFlow";
import { Home } from "../../../../model/Core/Home";
import { User } from "../../../../model/Core/User";

/* UI Components */
import { FoundHome } from "./HomeSearch/FoundHome";

/* 
================================================================
STATUS STEPS
================================================================
*/
interface StatusStep {
  name: string;
  substeps: Substep[];
}

interface Substep {
  text: string;
  isComplete: (buyingFlow: BuyingFlow, userData: User) => boolean;
  route: string;
  renderComponent?: React.FC<{
    buyingFlow: BuyingFlow;
    userData: User;
    home: Home;
  }>;
}

const statusSteps: StatusStep[] = [
  {
    name: "Home Search",
    substeps: [
      {
        text: "Found a home",
        isComplete: (buyingFlow, userData) => buyingFlow?.home_id != null,
        route: "/buyingflow/homesearch",
        renderComponent: FoundHome,
      },
    ],
  },
  {
    name: "Budgeting",
    substeps: [
      {
        text: "Find a lender or specify all cash",
        isComplete: (buyingFlow, userData) =>
          buyingFlow?.budgeting?.pre_approval_file_id != null ||
          buyingFlow?.budgeting?.paying_all_cash_amount != null,
        route: "/buyingflow/budgeting/introduction",
      },
      {
        text: "Upload and select pre-approval document",
        isComplete: (buyingFlow, userData) =>
          buyingFlow?.budgeting?.pre_approval_file_id != null ||
          buyingFlow?.budgeting?.paying_all_cash_amount != null,
        route: "/buyingflow/budgeting/pre-approval",
      },
      {
        text: "Upload proof of funds",
        isComplete: (buyingFlow, userData) =>
          (buyingFlow?.budgeting?.pre_approval_file_id != null ||
            buyingFlow?.budgeting?.paying_all_cash_amount != null) &&
          buyingFlow?.budgeting?.proof_of_funds_file_ids?.length > 0,
        route: "/buyingflow/budgeting/enter-proof",
      },
    ],
  },
  {
    name: "Offer & Negotiation",
    substeps: [
      {
        text: "Disclosure Analysis",
        isComplete: (buyingFlow, userData) =>
          buyingFlow?.offer_negotiation?.disclosure_analysis_acknowledgement !=
          null,
        route: "/buyingflow/offer-negotiation/disclosure-analysis",
      },
      {
        text: "Comparable Homes Analysis",
        isComplete: (buyingFlow, userData) =>
          buyingFlow?.offer_negotiation
            ?.comparable_sales_analysis_acknowledgement != null,
        route: "/buyingflow/offer-negotiation/comparable-homes",
      },
      {
        text: "Price Determination",
        isComplete: (buyingFlow, userData) =>
          buyingFlow?.offer_negotiation?.chosen_price != null,
        route: "/buyingflow/offer-negotiation/price-determination",
      },
      {
        text: "Write Offer",
        isComplete: (buyingFlow, userData) => false,
        route: "/buyingflow/offer-negotiation/offer-writing",
      },
    ],
  },
];

export { statusSteps };
