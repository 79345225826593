import { BuyingFlow } from "../../../model/Core/BuyingFlow";

/* 
================================================================
BUDGETING FLOW
================================================================
*/

/* Top level */
function budgetingIncomplete(buyingFlow: BuyingFlow): boolean {
  if (
    (buyingFlow.budgeting.pre_approval_file_id === null && // If no pre-approval and
      buyingFlow.budgeting.paying_all_cash_amount == null) || // No all cash amount
    /* OR */
    (typeof buyingFlow.budgeting.pre_approval_file_id === "string" && // Has pre-approval but ...
      buyingFlow.budgeting.proof_of_funds_file_ids.length === 0) || // No proof of funds chosen
    /* OR */
    (typeof buyingFlow.budgeting.paying_all_cash_amount === "number" && // Has cash amount but ..
      buyingFlow.budgeting.proof_of_funds_file_ids.length === 0) // No proof of funds chosen
  ) {
    return true;
  }
  return false;
}
function budgetingComplete(buyingFlow: BuyingFlow): boolean {
  return !budgetingIncomplete(buyingFlow);
}

export { budgetingIncomplete, budgetingComplete };
