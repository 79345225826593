/* React */
import { ChangeEvent, useEffect, useState, FocusEvent } from "react";
import { Link, useNavigate } from "react-router-dom";

/* Data Models */
import { User as UserDataModel } from "../../../model/Core/User";
import { BuyingFlow } from "../../../model/Core/BuyingFlow";
import { Home } from "../../../model/Core/Home";

/* Auth */
import { User } from "firebase/auth";
import { getCurrentUser } from "../../../firebase/authentication";
import { userSignedInRequirement, withRequirements } from "../../requirements";

/* APIs */
import {
  fetchCurrentUserData,
  fetchHomeCoverPhoto,
  fetchHomeData,
} from "../../../APIs/user";

/* UI Components */
import { statusSteps } from "./StatusFlowOptionalComponents/statusSteps";

/* Styling */
import "./dashboard.css";
import kaiPhoto from "../../../assets/kia_avatar.svg";
import magnifyingGlass from "../../../assets/magnifying-glass.svg";
import feedIcon from "../../../assets/feed_icon.svg";
import defaultPersonPhoto from "../../../assets/default_person_photo.svg";
import pdfIcon from "../../../assets/pdf_icon.svg";

function Dashboard() {
  /* 
  ================================================================
  CONSTANTS
  ================================================================
  */
  const currentDate = new Date().toLocaleDateString("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
  });
  const fixedPrefix = "Start a new search:  ";
  const animatedPart = "3 bed home in Cupertino";
  const typingSpeed = 100; // milliseconds per character
  const deletingSpeed = 50; // milliseconds per character
  const pauseTime = 2000; // pause time when text is fully typed or deleted
  const navigate = useNavigate();

  /* 
  ================================================================
  STATE VARIABLES
  ================================================================
  */
  /* Home Search Bar */
  const [homeSearchInput, setHomeSearchInput] = useState<string>("");
  const [homeSearchPlaceholderInput, setHomeSearchPlaceholderInput] =
    useState<string>("Start a new search:  ");
  const [homeSearchInputIsFocused, setHomeSearchInputIsFocused] =
    useState<boolean>(false);

  /* User data */
  const [userData, setUserData] = useState<UserDataModel | null>(null);

  /* Buying Flow / Home */
  const [selectedBuyingFlow, setSelectedBuyingFlow] =
    useState<BuyingFlow | null>(null);
  const [selectedBuyingFlowHome, setSelectedBuyingFlowHome] =
    useState<Home | null>(null);
  const [
    selectedBuyingFlowHomeCoverImage,
    setSelectedBuyingFlowHomeCoverImage,
  ] = useState<string | null>(null); // base64
  const [buyingFlowOptions, setBuyingFlowOptions] = useState<
    { value: string; label: string }[]
  >([]);

  /* Status Flow */
  const [expandedStep, setExpandedStep] = useState<string | null>("");

  /* 
  ================================================================
  API: Pull user buying flows
  ================================================================
  */
  useEffect(() => {
    const fetchDataAndDetermineStep = async () => {
      let userPresent: User | null = await getCurrentUser();
      let userDataFetched: UserDataModel | null = null;
      if (userPresent) {
        userDataFetched = await fetchCurrentUserData();
      }
      if (!userDataFetched) {
        return;
      }

      setUserData(userDataFetched);

      if (userDataFetched.buying_flows.length > 0) {
        let defaultBuyingFlow = userDataFetched.buying_flows[0];
        setSelectedBuyingFlow(defaultBuyingFlow);

        let homeData: Home | null = await fetchHomeData(
          defaultBuyingFlow.home_id
        );
        setSelectedBuyingFlowHome(homeData);

        let homeCoverPhoto: string | null = await fetchHomeCoverPhoto(
          defaultBuyingFlow.home_id
        );
        setSelectedBuyingFlowHomeCoverImage(homeCoverPhoto);
      }
    };

    fetchDataAndDetermineStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* 
  ================================================================
  API: Get Home data related to user buying flow options
  ================================================================
  */
  useEffect(() => {
    getBuyingFlowOptions().then(setBuyingFlowOptions);
  }, [userData?.buying_flows]);

  const getBuyingFlowOptions = async () => {
    if (!userData?.buying_flows) {
      return [];
    }

    const options = await Promise.all(
      userData.buying_flows.map(async (flow) => {
        const address = await getHomeAddress(flow.id.toString());
        return { value: flow.id.toString(), label: address };
      })
    );

    return options;
  };

  const getHomeAddress = async (buyingFlowId: string): Promise<string> => {
    const buyingFlow = userData?.buying_flows.find(
      (flow) => flow.id.toString() === buyingFlowId
    );
    if (buyingFlow && buyingFlow.home_id) {
      try {
        const homeData = await fetchHomeData(buyingFlow.home_id);
        if (homeData && homeData.address && homeData.address.address_line_1) {
          return homeData.address.address_line_1;
        }
      } catch (error) {
        console.error("Error fetching home data:", error);
      }
    }
    return "Select a Home"; // Default if home address is not available
  };

  /* 
  ================================================================
  FUNC: Determine 1st incomplete step and substep
  ================================================================
  */

  const firstIncompleteStepIndex = statusSteps.findIndex((step) => {
    return !step.substeps.every((substep) =>
      selectedBuyingFlow && userData
        ? substep.isComplete(selectedBuyingFlow, userData)
        : false
    );
  });

  const firstIncompleteSubstep =
    firstIncompleteStepIndex !== -1
      ? statusSteps[firstIncompleteStepIndex].substeps.find((substep) =>
          selectedBuyingFlow && userData
            ? !substep.isComplete(selectedBuyingFlow, userData)
            : false
        )
      : null;

  /* 
  ================================================================
  RENDER: EVENT HANDLERS
  ================================================================
  */
  const handleHomeSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setHomeSearchInput(e.target.value);
  };

  const handleHomeSearchFocus = (): void => {
    setHomeSearchInputIsFocused(true);
  };

  const handleHomeSearchBlur = (e: FocusEvent<HTMLInputElement>): void => {
    // Only unfocus if the input is empty
    if (e.target.value === "") {
      setHomeSearchInputIsFocused(false);
      setHomeSearchPlaceholderInput(fixedPrefix); // Reset to just the prefix
    }
  };

  const handleStepHover = (stepName: string) => {
    setExpandedStep(stepName);
  };

  const handleStepLeave = () => {
    setExpandedStep(null);
  };

  const handleBuyingFlowChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedFlowId = event.target.value;
    const selectedFlow = userData?.buying_flows.find(
      (flow) => flow.id.toString() === selectedFlowId
    );
    setSelectedBuyingFlowHome(null);
    setSelectedBuyingFlowHomeCoverImage(null);
    setSelectedBuyingFlow(selectedFlow || null);

    if (selectedFlow) {
      fetchHomeData(selectedFlow.home_id).then(setSelectedBuyingFlowHome);
      fetchHomeCoverPhoto(selectedFlow.home_id).then(
        setSelectedBuyingFlowHomeCoverImage
      );
    } else {
      setSelectedBuyingFlowHome(null);
      setSelectedBuyingFlowHomeCoverImage(null);
    }
  };

  /* 
  ================================================================
  RENDER: Animate Home Search input bar
  ================================================================
  */
  useEffect(() => {
    if (homeSearchInputIsFocused) return;

    let timeout: NodeJS.Timeout;
    let isTyping = true;
    let currentIndex = 0;

    const animate = () => {
      if (isTyping) {
        // Typing animation - only animate the variable part
        if (currentIndex <= animatedPart.length) {
          setHomeSearchPlaceholderInput(
            fixedPrefix + animatedPart.substring(0, currentIndex)
          );
          currentIndex++;
          timeout = setTimeout(animate, typingSpeed);
        } else {
          // Pause at the end of typing
          isTyping = false;
          timeout = setTimeout(animate, pauseTime);
        }
      } else {
        // Deleting animation - only delete the variable part
        if (currentIndex > 0) {
          currentIndex--;
          setHomeSearchPlaceholderInput(
            fixedPrefix + animatedPart.substring(0, currentIndex)
          );
          timeout = setTimeout(animate, deletingSpeed);
        } else {
          // Pause before starting to type again
          isTyping = true;
          timeout = setTimeout(animate, pauseTime);
        }
      }
    };

    timeout = setTimeout(animate, pauseTime); // Start the animation

    return () => clearTimeout(timeout); // Clean up the timeout
  }, [homeSearchInputIsFocused]);

  /* 
  ================================================================
  RENDER
  ================================================================
  */
  if (!selectedBuyingFlow || !userData || !selectedBuyingFlowHome) {
    return <div>Loading...</div>;
  }

  return (
    <div className="dashboard">
      {/* Header */}
      <div className="dashboard__header">
        {/* Header Left (date/welcome) */}
        <div className="dashboard__header-left">
          <div className="dashboard__header-left-date">{currentDate}</div>
          <div className="dashboard__header-left-message">Welcome back!</div>
        </div>
        {/* Header Right (search) */}
        <div className="dashboard__header-right">
          <div className="dashboard__header-right-search">
            <img
              src={kaiPhoto}
              className="dashboard__header-right-search-kai-photo"
            />
            <input
              type="text"
              placeholder={homeSearchPlaceholderInput}
              className="dashboard__header-right-search-input"
              value={homeSearchInput}
              onChange={handleHomeSearchChange}
              onFocus={handleHomeSearchFocus}
              onBlur={handleHomeSearchBlur}
            />
            <img
              src={magnifyingGlass}
              className="dashboard__header-right-search-magnifying-glass"
            />
          </div>
        </div>
      </div>

      {/* Body */}
      <div className="dashboard__body">
        {/* Left-side Content */}
        <div className="dashboard__body-left">
          {/* Home Preview */}
          <div className="dashboard__body-left-home-preview">
            {selectedBuyingFlowHomeCoverImage ? (
              <img
                src={`data:image/jpeg;base64,${selectedBuyingFlowHomeCoverImage}`}
                className="dashboard__body-left-home-preview-photo"
                alt={
                  selectedBuyingFlowHome?.address?.address_line_1 ||
                  "Home Cover"
                }
              />
            ) : (
              <div className="dashboard__body-left-home-preview-photo-loading"></div>
            )}
            <div className="dashboard__body-left-home-preview-info">
              <select
                className="dashboard__body-left-home-preview-info-home-name"
                value={selectedBuyingFlow?.id.toString() || ""}
                onChange={handleBuyingFlowChange}
              >
                {buyingFlowOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <div className="dashboard__body-left-home-preview-info-list-price">
                $
                {selectedBuyingFlowHome && selectedBuyingFlowHome.listed_price
                  ? selectedBuyingFlowHome.listed_price.toLocaleString("en-US")
                  : ""}
              </div>
            </div>
          </div>

          {/* Feed */}
          <div className="dashboard__body-left-feed">
            {/* Feed Header */}
            <div className="dashboard__body-left-feed-header">
              <div>
                <div className="dashboard__body-left-feed-header-left-title">
                  Feed
                </div>
                <div className="dashboard__body-left-feed-header-left-subtitle">
                  {selectedBuyingFlowHome &&
                    selectedBuyingFlowHome.address.address_line_1}
                </div>
              </div>
              <div className="dashboard__body-left-feed-header-right">
                <span className="dashboard__body-left-feed-header-right-text">
                  0 updates
                </span>
                <img
                  className="dashboard__body-left-feed-header-right-icon"
                  src={feedIcon}
                />
              </div>
            </div>

            {/* Feed Content */}
            <div className="dashboard__body-left-feed-body">
              Feature Coming Soon
            </div>
          </div>

          {/* My Team */}
          <div className="dashboard__body-left-myteam">
            {/* My Team Header */}
            <div className="dashboard__body-left-myteam-header">
              <div>
                <div className="dashboard__body-left-myteam-header-left-title">
                  My Team
                </div>
                <div className="dashboard__body-left-myteam-header-left-subtitle">
                  {selectedBuyingFlowHome &&
                    selectedBuyingFlowHome.address.address_line_1}
                </div>
              </div>
              <div className="dashboard__body-left-myteam-header-right">
                <span className="dashboard__body-left-myteam-header-right-text">
                  See messages
                </span>
                <span className="dashboard__body-left-myteam-header-right-icon">
                  &gt;
                </span>
              </div>
            </div>

            {/* My Team Body */}
            <div className="dashboard__body-left-myteam-body">
              {/* Kai AI */}
              <div className="dashboard__body-left-myteam-body-contact">
                <img
                  src={kaiPhoto}
                  className="dashboard__body-left-myteam-body-contact-photo"
                />
                <div className="dashboard__body-left-myteam-body-contact-kai-info">
                  Kai AI
                </div>
                <div className="dashboard__body-left-myteam-body-contact-kai-chat">
                  Chat
                </div>
              </div>

              {/* Sellers Agent */}
              <div className="dashboard__body-left-myteam-body-contact">
                <img
                  src={defaultPersonPhoto}
                  className="dashboard__body-left-myteam-body-contact-photo"
                />
                <div className="dashboard__body-left-myteam-body-contact-info">
                  <div className="dashboard__body-left-myteam-body-contact-info-name">
                    {selectedBuyingFlowHome &&
                      selectedBuyingFlow?.contacts.sellers_agent?.name}
                  </div>
                  <div className="dashboard__body-left-myteam-body-contact-info-role">
                    Sellers Agent
                  </div>
                  <div className="dashboard__body-left-myteam-body-contact-info-contact">
                    {(selectedBuyingFlowHome &&
                      selectedBuyingFlow?.contacts.sellers_agent?.phone) ||
                      "Phone number unknown"}
                  </div>
                  <div className="dashboard__body-left-myteam-body-contact-info-contact">
                    {(selectedBuyingFlowHome &&
                      selectedBuyingFlow?.contacts.sellers_agent?.email) ||
                      "Email unknown"}
                  </div>
                </div>
              </div>

              {/* Lending Agent */}
              <div className="dashboard__body-left-myteam-body-contact">
                <img
                  src={defaultPersonPhoto}
                  className="dashboard__body-left-myteam-body-contact-photo"
                />
                <div className="dashboard__body-left-myteam-body-contact-info">
                  <div className="dashboard__body-left-myteam-body-contact-info-name">
                    {(selectedBuyingFlowHome &&
                      selectedBuyingFlow?.contacts.lenders_agent?.name) ||
                      "(no lending agent yet)"}
                  </div>
                  <div className="dashboard__body-left-myteam-body-contact-info-role">
                    Lending Agent
                  </div>
                  <div className="dashboard__body-left-myteam-body-contact-info-contact">
                    {(selectedBuyingFlowHome &&
                      selectedBuyingFlow?.contacts.lenders_agent?.phone) ||
                      "Phone number unknown"}
                  </div>
                  <div className="dashboard__body-left-myteam-body-contact-info-contact">
                    {(selectedBuyingFlowHome &&
                      selectedBuyingFlow?.contacts.lenders_agent?.email) ||
                      "Email unknown"}
                  </div>
                </div>
              </div>

              {/* Escrow Agent */}
              <div className="dashboard__body-left-myteam-body-contact">
                <img
                  src={defaultPersonPhoto}
                  className="dashboard__body-left-myteam-body-contact-photo"
                />
                <div className="dashboard__body-left-myteam-body-contact-info">
                  <div className="dashboard__body-left-myteam-body-contact-info-name">
                    {(selectedBuyingFlowHome &&
                      selectedBuyingFlow?.contacts.escrow_agent?.name) ||
                      "(no escrow agent yet)"}
                  </div>
                  <div className="dashboard__body-left-myteam-body-contact-info-role">
                    Lending Agent
                  </div>
                  <div className="dashboard__body-left-myteam-body-contact-info-contact">
                    {(selectedBuyingFlowHome &&
                      selectedBuyingFlow?.contacts.escrow_agent?.phone) ||
                      "Phone number unknown"}
                  </div>
                  <div className="dashboard__body-left-myteam-body-contact-info-contact">
                    {(selectedBuyingFlowHome &&
                      selectedBuyingFlow?.contacts.escrow_agent?.email) ||
                      "Email unknown"}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* My Documents */}
          <div className="dashboard__body-left-documents">
            {/* My Documents Header */}
            <div className="dashboard__body-left-documents-header">
              My Documents
            </div>

            {/* My Documents Body */}
            <div className="dashboard__body-left-documents-body">
              {userData?.documents.pre_approvals?.map((doc) => (
                <div
                  key={doc.id.toString()}
                  className="dashboard__body-left-documents-body-document"
                >
                  <img
                    src={pdfIcon}
                    className="dashboard__body-left-documents-body-document-pdf-icon"
                  />
                  {doc.filename}
                </div>
              ))}
              {userData?.documents.proof_of_funds?.map((doc) => (
                <div
                  key={doc.id.toString()}
                  className="dashboard__body-left-documents-body-document"
                >
                  <img
                    src={pdfIcon}
                    className="dashboard__body-left-documents-body-document-pdf-icon"
                  />
                  {doc.filename}
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* =================================================================================== */}

        {/* Body Right */}
        <div className="dashboard__body-right">
          {/* Header */}
          <div className="dashboard__body-right-header">
            <select
              className="dashboard__body-right-header-property-name"
              value={selectedBuyingFlow?.id.toString() || ""}
              onChange={handleBuyingFlowChange}
            >
              {buyingFlowOptions.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  className="dashboard__body-right-header-property-name-option"
                >
                  {option.label}
                </option>
              ))}
            </select>
            <Link
              className="dashboard__body-right-header-status"
              to={"/buyingflow" + `?flow=${selectedBuyingFlow.id}`}
            >
              Continue buying flow
            </Link>
          </div>

          {/* Status Flow */}
          <div
            className="dashboard__body-right-step-section"
            onMouseLeave={handleStepLeave}
          >
            {statusSteps.map((step, index) => {
              const allSubstepsComplete = step.substeps.every((substep) =>
                selectedBuyingFlow && userData
                  ? substep.isComplete(selectedBuyingFlow, userData)
                  : false
              );

              const isStepInProgress = index === firstIncompleteStepIndex;

              return (
                <div
                  key={step.name}
                  className={`dashboard__body-right-step ${
                    allSubstepsComplete
                      ? "complete"
                      : isStepInProgress
                      ? "inprogress"
                      : "future"
                  }`}
                  onMouseEnter={() => handleStepHover(step.name)}
                >
                  <span className="dashboard__body-right-step-name">
                    {step.name}
                  </span>
                  <div
                    className="dashboard__body-right-step-substeps"
                    style={{
                      maxHeight: isStepInProgress
                        ? "500px"
                        : expandedStep === step.name
                        ? "500px"
                        : "0",
                      transition: "max-height 1s ease-in-out",
                      overflow: "hidden",
                    }}
                  >
                    {step.substeps.map((substepType) => (
                      <div
                        key={substepType.text}
                        className={`dashboard__body-right-step-substep ${
                          selectedBuyingFlow &&
                          userData &&
                          substepType.isComplete(selectedBuyingFlow, userData)
                            ? "complete"
                            : firstIncompleteSubstep === substepType
                            ? "inprogress"
                            : ""
                        }`}
                      >
                        {substepType.text}
                        {substepType.renderComponent &&
                          selectedBuyingFlowHome && (
                            <substepType.renderComponent
                              {...{
                                buyingFlow: selectedBuyingFlow,
                                userData: userData,
                                home: selectedBuyingFlowHome,
                              }}
                            />
                          )}
                        {isStepInProgress &&
                          firstIncompleteSubstep == substepType && (
                            <button
                              className="dashboard__body-right-step-continue-button"
                              onClick={() =>
                                navigate(
                                  firstIncompleteSubstep.route +
                                    `?flow=${selectedBuyingFlow.id}`
                                )
                              }
                            >
                              Continue
                            </button>
                          )}
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRequirements(Dashboard, [userSignedInRequirement]);
