import BuyingFlowHouseIcon from "../../../../assets/buyingflow_nav_house_hunting.svg";
import BuyingFlowMoneyBagIcon from "../../../../assets/buyingflow_nav_budgeting.svg";
import BuyingFlowHandShakeIcon from "../../../../assets/buyingflow_nav_offer_and_negotiation.svg";
import BuyingFlowPaperworkIcon from "../../../../assets/buyingflow_nav_closing.svg";
import BuyingFlowKeysIcon from "../../../../assets/buyingflow_nav_keys.svg";

import BuyingFlowHouseIconComplete from "../../../../assets/buyingflow_nav_house_hunting_complete.svg";
import BuyingFlowMoneyBagIconComplete from "../../../../assets/buyingflow_nav_budgeting_complete.svg";
import BuyingFlowHandShakeIconComplete from "../../../../assets/buyingflow_nav_offer_and_negotiation_complete.svg";
import BuyingFlowPaperworkIconComplete from "../../../../assets/buyingflow_nav_closing_complete.svg";
import BuyingFlowKeysIconComplete from "../../../../assets/buyingflow_nav_keys_complete.svg";

export const buyingFlowSteps = [
  {
    title: "Home Search",
    icon: BuyingFlowHouseIcon,
    completeIcon: BuyingFlowHouseIconComplete,
  },
  {
    title: "Budgeting",
    icon: BuyingFlowMoneyBagIcon,
    completeIcon: BuyingFlowMoneyBagIconComplete,
  },
  {
    title: "Offer & Negotiation",
    icon: BuyingFlowHandShakeIcon,
    completeIcon: BuyingFlowHandShakeIconComplete,
  },
  {
    title: "Closing",
    icon: BuyingFlowPaperworkIcon,
    completeIcon: BuyingFlowPaperworkIconComplete,
  },
  {
    title: "Get the Keys",
    icon: BuyingFlowKeysIcon,
    completeIcon: BuyingFlowKeysIconComplete,
  },
];
