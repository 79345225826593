import { Routes, Route, Navigate } from "react-router-dom";

import React from "react";

// Layout
import Header from "../Layout/Header/Header";
import Footer from "../Layout/Footer/Footer";

// Pages
import NotFound from "../Pages/NotFound/NotFound";

import "./App.css";
import Signup from "../Pages/Signup/Signup";
import LogIn from "../Pages/Login/LogIn";
import SendPhoneVerificationCode from "../Pages/SendPhoneVerificationCode/SendPhoneVerificationCode";
import VerifyPhone from "../Pages/VerifyPhoneVerificationCode/VerifyPhoneVerificationCode";
import VerifyEmail from "../Pages/VerifyEmailVerificationCode/VerifyEmailVerificationCode";
import BuyingFlow from "../Pages/BuyingFlow/BuyingFlow";
import DashboardOld from "../Dashboard/Dashboard";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Landing from "../Pages/Landing/Landing";

function App() {
  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/buyingflow/*" element={<BuyingFlow />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<LogIn />} />
        <Route path="/dashboard-old" element={<DashboardOld />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route
          path="/send-phone-verification-code"
          element={<SendPhoneVerificationCode />}
        />
        <Route path="/verify-phone" element={<VerifyPhone />} />
        <Route path="/verify-email" element={<VerifyEmail />} />

        {/* Void */}
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
