import { getWithAuth, postWithAuth } from "../firebase/authentication";
import { Offer } from "../model/Core/Offer";

const AGNSY_API_BASE_URL = process.env.REACT_APP_AGNSY_SERVER_ADDRESS;
const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

/**
 * Starts a new LLM session.
 * @returns {Promise<string>} The thread ID of the new session.
 * @throws Will throw an error if the request fails.
 */
export async function StartLLMSession() {
  try {
    const response = await postWithAuth(
      `${HTTP_PROTOCAL}://${AGNSY_API_BASE_URL}/api/ai/chat/start`
    );

    if (!response.ok) {
      throw new Error(`Failed to start LLM session: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error starting LLM session:", error);
    throw error;
  }
}

/**
 * Sends a message to the LLM and retrieves the response.
 * @param {string} message - The message to send.
 * @param {string} threadId - The thread ID of the session.
 * @returns {Promise<string>} The response from the LLM.
 * @throws Will throw an error if the request fails.
 */
export async function SendLLMMessage(message: string, threadId: string) {
  const formData = new FormData();
  formData.append("message", message);
  formData.append("thread_id", threadId);

  try {
    const response = await postWithAuth(
      `${HTTP_PROTOCAL}://${AGNSY_API_BASE_URL}/api/ai/chat/send-message`,
      formData
    );

    if (!response.ok) {
      throw new Error(`Failed to send LLM message: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error sending LLM message:", error);
    throw error;
  }
}

/**
 * Grabs fresh new offer.
 * @returns {Promise<Offer>} The fresh offer object.
 * @throws Will throw an error if the request fails.
 */
export async function getBlankOffer(): Promise<Offer> {
  try {
    const response = await getWithAuth(
      `${HTTP_PROTOCAL}://${AGNSY_API_BASE_URL}/api/ai/offer-writer/start`
    );

    if (!response.ok) {
      throw new Error(`Failed to get starting offer: ${response.statusText}`);
    }

    const data = await response.json();
    return data as Offer;
  } catch (error) {
    console.error("Error getting offer:", error);
    throw error;
  }
}
