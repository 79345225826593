import { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { DashboardTitle } from "./Steps/Layout/Layout";
import HomeSearch from "./Steps/HomeSearch/HomeSearch";
import OfferNegotiation from "./Steps/OfferNegotiation/OfferNegotiation";
import Closing from "./Steps/Closing/Closing";
import GetKeys from "./Steps/GetKeys/GetKeys";
import BuyingFlowNav from "./BuyingFlowNav/BuyingFlowNav";
import { User as UserDataModel } from "../../../model/Core/User";
import { BuyingFlow as BuyingFlowDataModel } from "../../../model/Core/BuyingFlow";
import { fetchCurrentUserData, fetchHomeData } from "../../../APIs/user";
import "./buyingflow.css";
import {
  BUYING_FLOW_STEP,
  buyingFlowStepMapping,
} from "./Steps/StepComponents";
import Budgeting from "./Steps/Budgeting/Budgeting";
import HomeFlowNonexistent from "./Invalid/HomeFlowNonexistent";
import NoHomeFlowSelected from "./Invalid/NoHomeFlowSelected";
import { Home } from "../../../model/Core/Home";
import { getCurrentUser } from "../../../firebase/authentication";
import { User } from "firebase/auth";
import { budgetingIncomplete } from "./status";
import { ToolProvider } from "./Tools/ToolViewerContext";

function BuyingFlow() {
  /* 
  ================================================================
  CONSTANTS
  ================================================================
  */
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const flowId = searchParams.get("flow")?.replace(/^"|"$/g, "");
  const flowIdUrlParameter = flowId ? `?flow=${flowId}` : "";

  /* 
  ================================================================
  STATE VARIABLES
  ================================================================
  */
  const [currentStep, setCurrentStep] = useState<BUYING_FLOW_STEP | null>(null);
  const [currentHome, setCurrentHome] = useState<Home | null>(null);
  const [dynamicallyDeterminedStep, setDynamicallyDeterminedStep] = useState<
    string | null
  >(null);

  /* 
  ================================================================
  HOOK: DETERMINE BUYINGFLOW STEP
  ================================================================
  */
  useEffect(() => {
    const fetchDataAndDetermineStep = async () => {
      let userPresent: User | null = await getCurrentUser();
      let userData: UserDataModel | null = null;
      if (userPresent) {
        userData = await fetchCurrentUserData();
      }

      let buyingFlowData: BuyingFlowDataModel | null = null;

      if (flowId && userData !== null) {
        buyingFlowData =
          userData.buying_flows.find((flow) => flow.id === flowId) || null;
        if (buyingFlowData !== null) {
          let homeData: Home | null = await fetchHomeData(
            buyingFlowData.home_id
          );
          setCurrentHome(homeData);
        }
      }

      if (buyingFlowData === null) {
        setCurrentHome(null);
      }

      const determinedStep = determineBuyingFlowStep(userData, buyingFlowData);
      setDynamicallyDeterminedStep(determinedStep);
    };

    fetchDataAndDetermineStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const determineBuyingFlowStep = (
    userData: UserDataModel | null,
    buyingFlowData: BuyingFlowDataModel | null
  ): string => {
    /*
     * Validity checks
     */
    // No user data, go to dashboard and let it handle it lol
    if (userData == null) {
      return "/dashboard";
    }
    // If there is a flowId url param, but it yields no data, say not existent
    if (flowId != null && buyingFlowData == null) {
      return "/buyingflow/flow-nonexistent" + flowIdUrlParameter;
    }
    // When no flowId specified (no flow data), just start them at Home Search
    if (/* Implied flowId == null */ buyingFlowData == null) {
      return "/buyingflow/homesearch";
    }

    /*
     * BuyingFlow Step Determintation
     */
    // TODO: logic to determine whether to go to
    // /buyingflow/homesearch
    // /buyingflow/budgeting
    // /buyingflow/offer-negotiation
    // /buyingflow/closing
    // /buyingflow/get-keys

    /*
     * Check if Budgeting is incomplete
     */
    if (budgetingIncomplete(buyingFlowData)) {
      return "/buyingflow/budgeting" + flowIdUrlParameter;
    }

    /*
     * Check if Offer Negotiation is incomplete
     */
    if (
      buyingFlowData.offer_negotiation.disclosure_analysis_acknowledgement ==
        null || // Disclosure analysis not ran yet
      buyingFlowData.offer_negotiation
        .comparable_sales_analysis_acknowledgement == null || // Comp Sales not ran yet
      buyingFlowData.offer_negotiation.chosen_price == null // User hasn't chosen price
    ) {
      return "/buyingflow/offer-negotiation" + flowIdUrlParameter;
    }

    /*
     * TODO: Check if Closing is done
     */

    /*
     * TODO: Check if Closing is done
     */

    // Go to default/last step if all checks pass
    return "/buyingflow/closing" + flowIdUrlParameter;
  };

  /* 
  ================================================================
  HOOK: DETECT HIGHLEVEL STEP CHANGES, FOR FLOW SIDE NAV UI
  ================================================================
  */
  useEffect(() => {
    const currentPath =
      Object.keys(buyingFlowStepMapping).find((path) =>
        location.pathname.includes(path)
      ) ?? null;
    if (currentPath) {
      setCurrentStep(buyingFlowStepMapping[currentPath]);
    }
  }, [location.pathname]);

  /* 
  ================================================================
  RENDER: DISPLAY LOADING UNTIL STEP IS DETERMINED
  ================================================================
  */
  if (dynamicallyDeterminedStep == null) {
    return (
      <div className="loading-spinner">
        <div className="loading-spinner__outer" />
        <div className="loading-spinner-text">Loading...</div>
      </div>
    );
  }

  /* 
  ================================================================
  RENDER: ROUTING
  ================================================================
  */
  return (
    <ToolProvider>
      <div className="buyingflow">
        {currentStep && <BuyingFlowNav step={currentStep} />}
        <div className="buyingflow-content">
          {currentStep && (
            <DashboardTitle
              step={currentStep}
              address={
                currentHome
                  ? currentHome.address.address_line_1 +
                    ", " +
                    currentHome.address.city +
                    ", " +
                    currentHome.address.state +
                    " " +
                    currentHome.address.zip_code
                  : ""
              }
            />
          )}

          <Routes>
            {/* Index behaviour: choose where to navigate to based on ?user and ?buyingflow data */}
            <Route
              index
              element={<Navigate to={dynamicallyDeterminedStep} replace />}
            />

            {/* BuyingFlow Steps */}
            <Route path="homesearch/*" element={<HomeSearch />} />
            <Route path="budgeting/*" element={<Budgeting />} />
            <Route path="offer-negotiation/*" element={<OfferNegotiation />} />
            <Route path="closing/*" element={<Closing />} />
            <Route path="get-keys/*" element={<GetKeys />} />

            {/* Invalid */}
            <Route path="flow-nonexistent" element={<HomeFlowNonexistent />} />
            <Route path="none-selected" element={<NoHomeFlowSelected />} />
          </Routes>
        </div>
      </div>
    </ToolProvider>
  );
}

export default BuyingFlow;

/* 
  ================================================================
  FUNC: NAVIGATE AND KEEP URL PARAMS
  ================================================================
  */

export const useNavigateWithFlowIdURLParam = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  searchParams.get("flow")?.replace(/^"|"$/g, "");
  const flow = searchParams.get("flow");

  const navigateWithFlow = (path: string) => {
    navigate(`${path}?flow=${flow}`);
  };

  return navigateWithFlow;
};
