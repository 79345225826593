import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import DashboardAvatar from "../../../../../assets/kia_avatar.svg";
import Chat from "../../../../../assets/chat.svg";
import "./budgeting.css";

// Import components
import BudgetingIntroduction from "./Intro/Introduction";
import BudgetingLender from "./Lender/Lender";
import BudgetingPreApproval from "./PreApproval/PreApproval";
import BudgetingEnterCash from "./Cash/Cash";
import BudgetingProof from "./Proof/Proof";
import { useEffect, useState } from "react";
import { fetchCurrentUserData } from "../../../../../APIs/user";
import {
  userEmailVerifiedRequirement,
  userPhoneVerifiedRequirement,
  userSignedInRequirement,
  withRequirements,
} from "../../../../requirements";
import IncompleteStep from "../IncompleteStep";
import { BuyingFlow } from "../../../../../model/Core/BuyingFlow";
import { User } from "../../../../../model/Core/User";
import { TOOLS, useToolContext } from "../../Tools/ToolViewerContext";
import AIChatViewer from "../../Tools/AIChat/AIChat";
import AIEmailViewer from "../../Tools/AIEmail/AIEmail";

function Budgeting() {
  /* 
  ================================================================
  CONSTANTS
  ================================================================
  */
  const [searchParams] = useSearchParams();
  const flowId = searchParams.get("flow")?.replace(/^"|"$/g, "");
  const flowIdUrlParameter = flowId ? `?flow=${flowId}` : "";

  /* 
  ================================================================
  STATE VARIABLES
  ================================================================
  */
  const [dynamicallyDeterminedStep, setDynamicallyDeterminedStep] = useState<
    string | null
  >(null);
  const { openTool, isToolOpen, currentTool } = useToolContext();

  /* 
  ================================================================
  HOOK: DETERMINE BUDGETING STEP
  ================================================================
  */
  useEffect(() => {
    const fetchDataAndDetermineStep = async () => {
      let userData: User | null = await fetchCurrentUserData();
      let buyingFlowData: BuyingFlow | null = null;

      if (flowId && userData !== null) {
        buyingFlowData =
          userData.buying_flows.find((flow) => flow.id === flowId) || null;
      }

      const determinedStep = determineBudgetingStep(userData, buyingFlowData);
      setDynamicallyDeterminedStep(determinedStep);
    };

    fetchDataAndDetermineStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const determineBudgetingStep = (
    userData: User | null,
    buyingFlowData: BuyingFlow | null
  ): string => {
    /*
     * Validity checks
     */
    // No user data, go to dashboard and let it handle it lol
    if (userData == null) {
      return "/dashboard";
    }
    // If there is a flowId url param, but it yields no data, say not existent
    if (flowId != null && buyingFlowData == null) {
      return "/buyingflow/flow-nonexistent" + flowIdUrlParameter;
    }
    // When no flowId specified (no flow data), just start them at Home Search
    if (/*flowId == null && */ buyingFlowData == null) {
      return "/buyingflow/none-selected";
    }

    /*
     * Budgeting Step Determintation
     */
    // TODO: logic to determine whether to go to
    // /buyingflow/budgeting/introduction
    // /buyingflow/budgeting/lender
    // /buyingflow/budgeting/pre-approval
    // /buyingflow/budgeting/enter-cash
    // /buyingflow/budgeting/enter-proof

    /*
     * If no pre-approval or no cash amount, send to intro
     */
    if (
      buyingFlowData.budgeting.pre_approval_file_id === null && // If no pre-approval and
      buyingFlowData.budgeting.paying_all_cash_amount === null // No all cash amount
    ) {
      return "/buyingflow/budgeting/introduction" + flowIdUrlParameter;
    }

    /*
     * If pre-approval or cash amount (but no proof of funds)
     */
    if (
      (typeof buyingFlowData.budgeting.pre_approval_file_id === "string" || // If pre-approval or
        typeof buyingFlowData.budgeting.paying_all_cash_amount === "number") && // cash amount
      /* BUT NO PROOF OF FUNDS */
      buyingFlowData.budgeting.proof_of_funds_file_ids.length === 0
    ) {
      return "/buyingflow/budgeting/enter-proof" + flowIdUrlParameter;
    }

    // If all checks passed, go to Offer Negotiation
    return "/buyingflow/offer-negotiation" + flowIdUrlParameter;
  };

  /* 
  ================================================================
  RENDER: DISPLAY LOADING UNTIL STEP IS DETERMINED
  ================================================================
  */
  if (dynamicallyDeterminedStep == null) {
    return (
      <div className="loading-spinner">
        <div className="loading-spinner__outer" />
        <div className="loading-spinner-text">Loading...</div>
      </div>
    );
  }

  /* 
  ================================================================
  RENDER: ROUTING
  ================================================================
  */
  return (
    <div className="budgeting">
      {isToolOpen && currentTool === TOOLS.AI_CHAT && <AIChatViewer />}

      {isToolOpen && currentTool === TOOLS.AI_EMAIL && <AIEmailViewer />}

      <div className="budgeting__header">
        <img className="budgeting__avatar" src={DashboardAvatar} alt="Kia" />

        <div
          className="budgeting__chat-icon-container"
          onClick={() => openTool(TOOLS.AI_CHAT)}
        >
          <img className="budgeting__chat-icon" src={Chat} alt="chat" />
        </div>
      </div>

      <Routes>
        {/* Index behaviour: choose where to navigate to based on ?user and ?buyingflow data */}
        <Route
          index
          element={<Navigate to={dynamicallyDeterminedStep} replace />}
        />

        {/* Budgeting Substeps */}
        <Route path="introduction" element={<BudgetingIntroduction />} />
        <Route path="lender" element={<BudgetingLender />} />
        <Route path="pre-approval" element={<BudgetingPreApproval />} />
        <Route path="enter-cash" element={<BudgetingEnterCash />} />
        <Route path="enter-proof" element={<BudgetingProof />} />

        {/* Incomplete */}
        <Route
          path="incomplete"
          element={
            <IncompleteStep
              stepName="Budgeting"
              reroute="/buyingflow/budgeting"
            />
          }
        />
      </Routes>
    </div>
  );
}

export default withRequirements(Budgeting, [
  userSignedInRequirement,
  userEmailVerifiedRequirement,
  userPhoneVerifiedRequirement,
]);
