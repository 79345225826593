/* React */
import { useEffect, useRef, useState } from "react";

/* Data Model */
import {
  ByProductEdit,
  Offer,
  OfferFieldLocation,
} from "../../../../../model/Core/Offer";

/* Functionality */
import { useToolContext } from "../ToolViewerContext";
import { Document, Page, pdfjs } from "react-pdf";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";

/* APIs */
import { getBlankOffer } from "../../../../../APIs/ai";

/* Styling */
import AISparkles from "../../../../../assets/ai_sparkles.svg";
import "./ai-offer-writer.css";
import { getFile } from "../../../../../APIs/file";

// Set the PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export interface PageDimensions {
  width: number;
  height: number;
}

const AIOfferWriter: React.FC = () => {
  /* 
  ================================================================
  CONSTANTS
  ================================================================
  */
  const offerWriterRef = useRef<HTMLDivElement>(null);
  const documentContainerRef = useRef<HTMLDivElement>(null);
  const pageContainerRef = useRef<HTMLDivElement>(null);

  /* 
  ================================================================
  STATE VARS
  ================================================================
  */
  const [offer, setOffer] = useState<Offer | null>(null);
  const [offerPDFs, setOfferPDFs] = useState<Blob[]>([]);
  const [documentDimensions, setDocumentDimensions] = useState<PageDimensions>({
    width: 0,
    height: 0,
  });
  const [pageDimensions, setPageDimensions] = useState<PageDimensions>({
    width: 650,
    height: 1000,
  });
  const [editingValues, setEditingValues] = useState<Record<string, string>>(
    {}
  );
  const [highlightedFieldLocation, setHighlightedFieldLocation] =
    useState<OfferFieldLocation | null>(null);
  const [documentIndex, setDocumentIndex] = useState<number>(0); // 0-index
  const [pageIndex, setPageIndex] = useState<number>(3); // 1-index
  const [currentDocumentTotalPages, setCurrentDocumentTotalPages] =
    useState<number>(Number.MAX_SAFE_INTEGER);
  const [pdfIsUpdating, setPdfIsUpdating] = useState<boolean>(false);

  /* 
  ================================================================
  CONTEXTS
  ================================================================
  */
  const { closeTool } = useToolContext();

  /* 
  ================================================================
  HOOK: exiting out
  ================================================================
  */
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        offerWriterRef.current &&
        !offerWriterRef.current.contains(event.target as Node)
      ) {
        closeTool();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeTool]);

  /* 
  ================================================================
  API: grabbing fresh offer
  ================================================================
  */
  useEffect(() => {
    const fetchData = async () => {
      setPdfIsUpdating(true);
      let offer = await getBlankOffer();

      // Fetch files
      const files = await Promise.all(
        offer.documents.map(({ file: { namespace, path_in_namespace } }) =>
          getFile(namespace, path_in_namespace)
        )
      );

      // Edit the files with all fields that are already populated
      // Modify PDFs with existing field values
      const updatedFiles = await Promise.all(
        files.map(async (file, documentIndex) => {
          const pdfDoc = await PDFDocument.load(await file.arrayBuffer(), {
            ignoreEncryption: true,
          });
          const boldFont = await pdfDoc.embedFont(
            StandardFonts.HelveticaBoldOblique
          );
          const pages = pdfDoc.getPages();

          // Iterate through sections and fields to place existing values
          offer.documents[documentIndex].sections.forEach((section) => {
            section.fields.forEach((field) => {
              if (!field.value) return;

              field.locations.forEach((location: OfferFieldLocation) => {
                const page = pages[location.page - 1]; // Ensure pageIndex is 1-based
                const { width: pageWidth, height: pageHeight } = page.getSize();
                const x_scaled = location.x * pageWidth;
                const y_scaled = location.y * pageHeight;

                if (!field.value) return;
                // Block out previous value
                page.drawRectangle({
                  x: x_scaled,
                  y: y_scaled,
                  width: location.width * pageWidth,
                  height: location.height * pageHeight,
                  color: rgb(1, 1, 1), // White background
                  opacity: 1, // Full opacity
                });

                // Add field value
                page.drawText(field.value, {
                  x: x_scaled,
                  y: y_scaled,
                  size: 12,
                  font: boldFont,
                  color: rgb(0, 0, 1), // Blue text
                });
              });

              if (!field.byproduct_edits) return;

              // Byproduct edits
              field.byproduct_edits.forEach((byproduct_edit: ByProductEdit) => {
                byproduct_edit.locations.forEach(
                  (location: OfferFieldLocation) => {
                    const page = pdfDoc.getPages()[location.page - 1]; // Ensure pageIndex is 1-based
                    const { width: pageWidth, height: pageHeight } =
                      page.getSize();
                    const x_scaled = location.x * pageWidth;
                    const y_scaled = location.y * pageHeight;

                    // Block out previous value
                    page.drawRectangle({
                      x: x_scaled,
                      y: y_scaled,
                      width: location.width * pageWidth,
                      height: location.height * pageHeight,
                      color: rgb(1, 1, 1), // White color
                      opacity: 1, // Ensure full opacity to fully cover existing text
                    });

                    if (!field.value) {
                      return;
                    }

                    // Add in new value
                    page.drawText(byproduct_edit.value, {
                      x: x_scaled,
                      y: y_scaled,
                      size: 12,
                      font: boldFont,
                      color: rgb(0, 0, 1),
                    });
                  }
                );
              });
            });
          });

          // Save updated PDF
          const pdfBytes = await pdfDoc.save();
          return new Blob([pdfBytes], { type: "application/pdf" });
        })
      );

      setOfferPDFs(updatedFiles);
      setOffer(offer);
      setTimeout(() => {
        setPdfIsUpdating(false);
      }, 900);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* 
  ================================================================
  FUNCTION: edit field
  ================================================================
  */
  const handleFieldUnselect = async (
    sectionIndex: number,
    fieldIndex: number,
    newValue: string
  ) => {
    // Unselect
    setHighlightedFieldLocation(null);

    if (!offer) return;
    const field =
      offer.documents[documentIndex].sections[sectionIndex].fields[fieldIndex];
    if (!field) return;
    if (
      newValue ==
      offer.documents[documentIndex].sections[sectionIndex].fields[fieldIndex]
        .value
    ) {
      return;
    }

    const updatedOffer = { ...offer };
    updatedOffer.documents[documentIndex].sections[sectionIndex].fields[
      fieldIndex
    ].value = newValue;
    setOffer(updatedOffer);

    // Edit PDF
    const pdfDoc = await PDFDocument.load(
      await offerPDFs[documentIndex].arrayBuffer(),
      {
        ignoreEncryption: true,
      }
    );
    const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBoldOblique);
    field.locations.forEach((location: OfferFieldLocation) => {
      const page = pdfDoc.getPages()[location.page - 1]; // Ensure pageIndex is 1-based
      const { width: pageWidth, height: pageHeight } = page.getSize();
      const x_scaled = location.x * pageWidth;
      const y_scaled = location.y * pageHeight;

      // Block out previous value
      page.drawRectangle({
        x: x_scaled,
        y: y_scaled - 1,
        width: location.width * pageWidth,
        height: location.height * pageHeight + 1,
        color: rgb(1, 1, 1), // White color
        opacity: 1, // Ensure full opacity to fully cover existing text
      });

      // Add in new value
      page.drawText(newValue, {
        x: x_scaled,
        y: y_scaled,
        size: 12,
        font: boldFont,
        color: rgb(0, 0, 1),
      });
    });

    if (!field.byproduct_edits) return;

    // Byproduct edits
    field.byproduct_edits.forEach((byproduct_edit: ByProductEdit) => {
      byproduct_edit.locations.forEach((location: OfferFieldLocation) => {
        const page = pdfDoc.getPages()[location.page - 1]; // Ensure pageIndex is 1-based
        const { width: pageWidth, height: pageHeight } = page.getSize();
        const x_scaled = location.x * pageWidth;
        const y_scaled = location.y * pageHeight;

        // Block out previous value
        page.drawRectangle({
          x: x_scaled,
          y: y_scaled - 1,
          width: location.width * pageWidth,
          height: location.height * pageHeight + 1,
          color: rgb(1, 1, 1), // White color
          opacity: 1, // Ensure full opacity to fully cover existing text
        });

        if (!field.value) {
          return;
        }

        // Add in new value
        page.drawText(byproduct_edit.value, {
          x: x_scaled,
          y: y_scaled,
          size: 12,
          font: boldFont,
          color: rgb(0, 0, 1),
        });
      });
    });

    setPdfIsUpdating(true);

    const pdfBytes = await pdfDoc.save();
    setOfferPDFs((prevOfferPDFs) => {
      const updatedPDFs = [...prevOfferPDFs];
      updatedPDFs[documentIndex] = new Blob([pdfBytes], {
        type: "application/pdf",
      });
      return updatedPDFs;
    });

    setTimeout(() => {
      setPdfIsUpdating(false);
    }, 900);
  };

  /* 
  ================================================================
  FUNCTIONS: Field change, view
  ================================================================
  */
  const handleFieldSelect = (sectionIndex: number, fieldIndex: number) => {
    if (!offer) return;

    const field =
      offer.documents[documentIndex].sections[sectionIndex].fields[fieldIndex];

    if (field.locations.length > 0) {
      // Go to the PDF page of the field's first location
      setPageIndex(field.locations[0].page);

      // Highlight the first location for the field
      setHighlightedFieldLocation(field.locations[0]);
    }

    // Scroll to center the field in the PDF view
    setTimeout(() => {
      if (documentContainerRef.current) {
        const container = documentContainerRef.current;
        const fieldY = field.locations[0].y;
        const fieldHeight = field.locations[0].height;

        // Center the field in the view
        const scrollTarget = fieldY + fieldHeight / 2;
        container.scrollTo({
          top: scrollTarget,
          behavior: "smooth",
        });
      }
    }, 100); // Delay to ensure page renders before scrolling
  };

  const handleTempInputChange = (
    documentIndex: number,
    sectionIndex: number,
    fieldIndex: number,
    value: string
  ) => {
    setEditingValues((prev) => ({
      ...prev,
      [`${documentIndex}-${sectionIndex}-${fieldIndex}`]: value,
    }));
  };

  /* 
  ================================================================
  FUNCTIONS: Page change
  ================================================================
  */
  const handlePageChange = (newPageIndex: number) => {
    if (newPageIndex > 0 && newPageIndex <= currentDocumentTotalPages) {
      setPageIndex(newPageIndex);
    }
  };

  /* 
  ================================================================
  RENDER FUNC: PDF Sizing
  ================================================================
  */
  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setCurrentDocumentTotalPages(numPages);
    if (documentContainerRef.current) {
      setDocumentDimensions({
        width: documentContainerRef.current.clientWidth,
        height: documentContainerRef.current.clientHeight,
      });
    }
  }

  function onPageLoadSuccess(): void {
    if (pageContainerRef.current) {
      setPageDimensions({
        width: pageContainerRef.current.clientWidth,
        height: pageContainerRef.current.clientHeight,
      });
    }
  }

  /* 
  ================================================================
  RENDER
  ================================================================
  */
  return (
    <div className="ai-offer-writer">
      <div className="ai-offer-writer__container" ref={offerWriterRef}>
        <div className="ai-offer-writer__header">
          <img
            className="ai-offer-writer__sparkle-icon"
            src={AISparkles}
            alt="chat"
          />
          <p>Offer Writer</p>
        </div>

        <div className="ai-offer-writer__content">
          {/* Field Editor */}
          <div className="ai-offer-writer__content-left">
            {offer?.documents[documentIndex].sections.map(
              (section, sectionIndex) => (
                <div key={sectionIndex} className="ai-offer-writer__section">
                  <div className="ai-offer-writer__section-name">
                    {section.name.toUpperCase()}
                  </div>
                  <div className="ai-offer-writer__section-fields">
                    {section.fields.map((field, fieldIndex) => {
                      const fieldKey = `${documentIndex}-${sectionIndex}-${fieldIndex}`;
                      return (
                        <div
                          key={fieldIndex}
                          className="ai-offer-writer__section-field"
                        >
                          <div className="ai-offer-writer__section-field-label">
                            {field.label}
                          </div>
                          <input
                            className="ai-offer-writer__section-field-value"
                            type="text"
                            value={editingValues[fieldKey] ?? field.value ?? ""}
                            placeholder="Enter"
                            disabled={!field.user_editable}
                            /* 1) When you click on a field value */
                            onFocus={() =>
                              handleFieldSelect(sectionIndex, fieldIndex)
                            }
                            /* 2) When you edit the field value */
                            onChange={(e) =>
                              handleTempInputChange(
                                documentIndex,
                                sectionIndex,
                                fieldIndex,
                                e.target.value
                              )
                            }
                            /* 3) When you click away from the field value */
                            onBlur={() =>
                              handleFieldUnselect(
                                sectionIndex,
                                fieldIndex,
                                editingValues[fieldKey] ?? field.value ?? null
                              )
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              )
            )}
          </div>

          {/* PDF */}
          <div
            className="ai-offer-writer__content-right"
            ref={documentContainerRef}
            style={{ minWidth: `${documentDimensions.width}px` }}
          >
            {/* Page Change Arrows */}
            <button
              className="ai-offer-writer__pdf-page-nav prev"
              style={{
                position: "absolute",
                right: `${pageDimensions.width - 30}px`,
              }}
              onClick={() => handlePageChange(pageIndex - 1)}
            >
              &lt; {/* Left arrow */}
            </button>
            <button
              className="ai-offer-writer__pdf-page-nav next"
              style={{ position: "absolute", right: "30px" }}
              onClick={() => handlePageChange(pageIndex + 1)}
            >
              &gt; {/* Right arrow */}
            </button>

            {pdfIsUpdating ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: "100%",
                  minWidth: `${pageDimensions.width}px`,
                  flexDirection: "column",
                  color: "black",
                  fontWeight: "400",
                }}
              >
                <div className="spinner-pdf"></div>
                Updating PDF
              </div>
            ) : (
              <Document
                file={offerPDFs[documentIndex]}
                loading={<div className="spinner"></div>} // PDF loading indicator
                renderMode="canvas"
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <div ref={pageContainerRef} style={{ position: "relative" }}>
                  <Page
                    pageNumber={pageIndex}
                    renderTextLayer={false}
                    loading={
                      <div
                        className="spinner"
                        style={{
                          minHeight: `${pageDimensions.height}px`,
                          minWidth: `${pageDimensions.width}px`,
                        }}
                      ></div>
                    }
                    height={documentDimensions.height}
                    scale={1.6}
                    onLoadSuccess={onPageLoadSuccess}
                  />

                  {/* Highlighted Field */}
                  {highlightedFieldLocation && (
                    <div
                      style={{
                        background: "transparent",
                        position: "absolute",
                        left: `calc(${
                          highlightedFieldLocation.x * pageDimensions.width - 4
                        }px)`,
                        bottom: `${
                          highlightedFieldLocation.y * pageDimensions.height - 4
                        }px`,
                        height: `${
                          highlightedFieldLocation.height *
                            pageDimensions.height +
                          8
                        }px`,
                        width: `${
                          highlightedFieldLocation.width *
                            pageDimensions.width +
                          8
                        }px`,
                        border: "2px solid rgb(54, 84, 255, 0.6)",
                        boxShadow: "0px 0px 10px rgb(54, 84, 255, 0.6)",
                        pointerEvents: "none", // Prevents interaction
                      }}
                    />
                  )}
                </div>
              </Document>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIOfferWriter;
