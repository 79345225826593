import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import PhoneInput from "react-phone-number-input";
import {
  getSignupErrorMessage,
  LoginStatusCodes,
  SignupMessage,
  SignupStatusCodes,
  UserLogin,
  UserSignup,
} from "../../../APIs/user";
import { notSignedInRequirement, withRequirements } from "../../requirements";

import "react-phone-number-input/style.css";
import homeIcon from "../../../assets/signup_home_icon.svg";
import agnsyLogo from "../../../assets/signup_agnsy_logo.svg";

import "./Signup.css";

function Signup() {
  /* 
    ================================
    VARS
    ================================
    */
  const [formData, setFormData] = useState({
    email: "",
    username: "",
    password: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const navigate = useNavigate();

  /* 
    ================================
    UI Functionality
    ================================
    */
  const validatePhoneNumber = (phoneNumber: string) => {
    const phone = parsePhoneNumberFromString(phoneNumber);
    if (!phone || !phone.isValid()) {
      return "Invalid phone number format";
    }
    return "";
  };
  const validateForm = (name: string, value: string) => {
    let error = "";

    if (!disableSubmission) {
      setErrorMessage(error);
      return;
    }

    if (name === "email" && !/\S+@\S+\.\S+/.test(value) && value.length > 0) {
      error = "Invalid email format.";
    } else if (name === "password" && value.length < 6 && value.length > 0) {
      error = "Password must be at least 6 characters.";
    } else if (name === "phoneNumber") {
      error = validatePhoneNumber(value);
    }

    setErrorMessage(error);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setErrorMessage("");
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateForm(name, value);
  };

  /* 
    ================================
    SUBMISSION
    ================================
    */
  const disableSubmission =
    !formData.email ||
    !/\S+@\S+\.\S+/.test(formData.email) ||
    formData.email.endsWith("@") ||
    formData.password.length < 6;

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    // Prepare the form data for signup
    const uploadData = new FormData();
    uploadData.append("email", formData.email);
    uploadData.append("username", formData.username);
    uploadData.append("password", formData.password);
    uploadData.append("first_name", formData.firstName);
    uploadData.append("last_name", formData.lastName);
    uploadData.append("phone", formData.phoneNumber);

    try {
      // Attempt to sign up the user and recieve signup status
      const signUpStatus: SignupStatusCodes = await UserSignup(uploadData);

      // Checks if user was successfully created
      if (signUpStatus === SignupStatusCodes.CREATED) {
        console.log("user signup successful");

        // Attempt to log in the user after successful signup
        const loginStatus: LoginStatusCodes = await UserLogin(uploadData);

        if (loginStatus === LoginStatusCodes.SUCCESS) {
          // If login was successful navigate to the phone verification page
          navigate(
            `/verify-phone?phone=${encodeURIComponent(
              formData.phoneNumber
            )}&action=account-verify`
          );
        }
      } else {
        // Get and display the appropriate error message
        const message = getSignupErrorMessage(signUpStatus);
        setErrorMessage(message);
      }
    } catch (error) {
      // Log any unexpected errors
      console.error("Error during signup: ", error);
      setErrorMessage(SignupMessage.UNEXPECTED_ERROR);
    }

    setIsLoading(false);
  };

  /* 
    ================================
    RENDERING
    ================================
    */

  return (
    <div className="signup_container">
      <img src={agnsyLogo} alt="agnsy-logo" className="signup_agnsy_logo" />
      <div className="signup_formWrapper">
        <img src={homeIcon} alt="home-icon" className="signup_home_icon" />
        <h2 className="signup_heading">Create an Agnsy account</h2>
        <p className="signup_subtitle">
          Let's get started with your home buying journey
        </p>

        <form>
          <div className="signup_inputGroup">
            <label className="signup_inputLabel" htmlFor="firstName">
              First Name
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
              placeholder="John"
              className="signup_inputField"
            />

            <label className="signup_inputLabel" htmlFor="lastName">
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
              placeholder="Appleseed"
              className="signup_inputField"
            />

            <label className="signup_inputLabel" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              placeholder="Email"
              className="signup_inputField"
            />

            <label className="signup_inputLabel">Phone Number</label>
            <PhoneInput
              international
              value={formData.phoneNumber}
              defaultCountry="US"
              onChange={(value: string | undefined) => {
                setFormData((prevState) => ({
                  ...prevState,
                  phoneNumber: value || "",
                }));
                validateForm("phoneNumber", value || "");
              }}
              required
              placeholder="Phone Number"
              className="signup_inputField"
            />

            <label className="signup_inputLabel" htmlFor="username">
              Username
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
              placeholder="Username"
              className="signup_inputField"
            />

            <label className="signup_inputLabel" htmlFor="password">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              placeholder="Password"
              className="signup_inputField"
            />
          </div>

          <div>
            <p
              className={`signup_alert ${
                errorMessage ? "signup_alert--visible" : ""
              }`}
            >
              {errorMessage}
            </p>
          </div>

          <div>
            {isLoading ? (
              <div className="login_spinner"></div> // Show spinner
            ) : (
              <button
                className="signup_button"
                onClick={handleSubmit}
                disabled={disableSubmission}
              >
                Create Account
              </button>
            )}
          </div>
        </form>
        <br />
        <div className="signup_already_user">
          Already a user? <a href="/login">Login instead.</a>
        </div>
      </div>
    </div>
  );
}

export default withRequirements(Signup, [notSignedInRequirement]);
