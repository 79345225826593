import React from "react";

// Styles
import "./ai-email.css";
import AISparkles from "../../../../../assets/ai_sparkles.svg";

// Tools
import { TOOLS, useToolContext } from "../ToolViewerContext";

const AIEmailViewer: React.FC = () => {
  const { isToolOpen, currentTool, closeTool } = useToolContext();

  // Conditionall Render
  if (!isToolOpen || currentTool !== TOOLS.AI_EMAIL) return null;

  return (
    <div className="ai-email-viewer">
      <div className="ai-email-viewer-container">
        <div className="ai-email-viewer__header">
          <img
            className="ai-email-viewer__sparkle-icon"
            src={AISparkles}
            alt="email"
          />

          <p className="ai-email-viewer__title">Email</p>

          <div className="ai-email-viewer__exit" onClick={closeTool}>
            x
          </div>
        </div>

        <div className="ai-email-viewer__email">
          <div className="ai-email-viewer__email-header">
            <div className="ai-email-viewer__recipient">
              <p className="ai-email-viewer__recipient-prefix">To:</p>
              <p className="ai-email-viewer__recipient-email">
                joe@rocketmortgage.com
              </p>
            </div>

            <div className="ai-email-viewer__sender">
              <p className="ai-email-viewer__sender-prefix">From:</p>
              <p className="ai-email-viewer__sender-email">
                matruiz@agnsyclient.com
              </p>
            </div>
          </div>

          <div className="ai-email-viewer__email-subject">
            <p className="ai-email-viewer__subject-prefix">Subject:</p>
            <p className="ai-email-viewer__subject-title">
              Request for Pre-Approval
            </p>
          </div>

          <div className="ai-email-viewer__email-body"></div>
        </div>
      </div>
    </div>
  );
};

export default AIEmailViewer;
