import { useNavigateWithFlowIdURLParam } from "../../../BuyingFlow";
import { ProofOfFundsFileResponse } from "../../../../../../model/Core/Files";
import {
  deleteUserProofOfFunds,
  getUserProofOfFunds,
} from "../../../../../../APIs/budgeting";
import {
  userEmailVerifiedRequirement,
  userPhoneVerifiedRequirement,
  userSignedInRequirement,
  withRequirements,
} from "../../../../../requirements";
import { useEffect, useState } from "react";
import { FileUpload, UploadType } from "../common";

// Styles
import DropDown from "../../../../../../assets/drop_down.svg";
import BringUp from "../../../../../../assets/bring_up.svg";
import Trash from "../../../../../../assets/delete.svg";
import "./proof.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getCurrentUserBuyingFlow,
  toggleBuyingFlowProofOfFunds,
} from "../../../../../../APIs/buyingflow";
import { BuyingFlow } from "../../../../../../model/Core/BuyingFlow";
import { UUIDTypes } from "uuid";

const BudgetingProof: React.FC = () => {
  /* 
  ================================================================
  CONSTANTS
  ================================================================
  */
  const [searchParams] = useSearchParams();
  const flowId = searchParams.get("flow")?.replace(/^"|"$/g, "");
  const navigateWithFlowId = useNavigateWithFlowIdURLParam();
  const navigate = useNavigate();

  /* 
  ================================================================
  STATE VARIABLES
  ================================================================
  */
  const [fileSelected, setFileSelected] = useState<boolean>(false);
  const [refreshKey, setRefreshKey] = useState<number>(0);

  /* 
  ================================================================
  HOOK: Ensure valid flow 
  ================================================================
  */
  useEffect(() => {
    const validateFlow = async () => {
      if (flowId == null) {
        navigate("/buyingflow/none-selected");
        return;
      }

      let flowData: BuyingFlow | null = await getCurrentUserBuyingFlow(flowId);
      if (flowData === null) {
        navigateWithFlowId("/buyingflow/flow-nonexistent");
        return;
      }
    };

    validateFlow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* 
  ================================================================
  NAVIGATION
  ================================================================
  */
  const handleEnterContinue = () => {
    navigateWithFlowId("/buyingflow/offer-negotiation");
  };

  /* 
  ================================================================
  RENDER
  ================================================================
  */
  return (
    <div className="proof">
      {/* Introduction Section */}
      <section className="proof__intro">
        <p className="proof__description proof__description--intro">
          Now time to upload your proof of funds. These documents will give the
          seller agent additional confidence behind your offered amount.
        </p>
      </section>

      {/* List Section */}
      <section className="proof__verification-steps">
        <p className="proof__description">
          To verify your financial readiness, upload documents showing
          accessible funds that altogether cover:
        </p>
        <ol className="proof__verification-steps-list">
          <li className="proof__verification-step-item">
            Your down payment amount
          </li>
          <li className="proof__verification-step-item">
            At least 3-6 months of reserves (mortgage if applicable + expenses)
          </li>
        </ol>
      </section>
      <section className="proof__document-types">
        <p className="proof__description">Appropriate document types: </p>
        <ul className="proof__document-types-list">
          <li className="proof__document-types-item">Bank Statements</li>
          <li className="proof__document-types-item">
            Investment / trading account statements
          </li>
          <li className="proof__document-types-item">
            Retirement account statements
          </li>
        </ul>
      </section>

      {/* Clarification Section */}
      <section className="proof__clarification">
        <p className="proof__description">
          Ensure documents clearly display your name, account balance, and date.
        </p>
        <p className="proof__description">
          Screenshots that are uploaded may require full statement upon request.
        </p>
      </section>

      {/* Upload Section */}
      <section className="proof__upload-section">
        <p className="proof__description proof__description--upload-area">
          Upload your Proof of Fund(s):
        </p>

        <FileUpload
          type={UploadType.PROOF_OF_FUNDS}
          onUploadComplete={() => setRefreshKey((prevKey) => prevKey + 1)}
        />
      </section>

      {/* File Analysis */}
      <section className="proof__files-analysis">
        <UserProofOfFunds
          setSelectedFileBoolean={setFileSelected}
          refreshKey={refreshKey}
        />
      </section>

      {/* Call to Action */}
      <section className="proof__cta">
        <button
          className="proof___cta-button"
          disabled={!fileSelected}
          onClick={handleEnterContinue}
        >
          Continue to Offer Writing
        </button>
      </section>
    </div>
  );
};

/* 
================================================================
User Proof of Funds List
================================================================
*/

interface UserProofOfFundsProps {
  setSelectedFileBoolean: React.Dispatch<React.SetStateAction<boolean>>;
  refreshKey: number;
}

const UserProofOfFunds: React.FC<UserProofOfFundsProps> = ({
  setSelectedFileBoolean,
  refreshKey,
}) => {
  /* 
  ================================================================
  CONSTANTS
  ================================================================
  */
  const [searchParams] = useSearchParams();
  const flowId = searchParams.get("flow");

  /* 
  ================================================================
  STATE VARIABLES
  ================================================================
  */
  const [files, setFiles] = useState<ProofOfFundsFileResponse[]>([]);
  const [expandedFiles, setExpandedFiles] = useState<boolean[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<
    ProofOfFundsFileResponse[]
  >([]);

  /* 
  ================================================================
  HOOKS: misc
  ================================================================
  */
  useEffect(() => {
    fetchProofOfFunds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey]);

  useEffect(() => {
    setSelectedFileBoolean(true ? selectedFiles.length > 0 : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiles]);

  /* 
  ================================================================
  HOOK: Toogle selected file on backend
  ================================================================
  */
  const handleCheckboxChange = async (proofOfFundsFileId: UUIDTypes) => {
    if (flowId === null) {
      alert("Not in a valid buying flow. Go to dashboard.");
      return;
    }

    // Update buying flow backend
    try {
      await toggleBuyingFlowProofOfFunds(flowId, proofOfFundsFileId);
    } catch (error) {
      alert(error);
      return;
    }

    // Set the selected files in the UI
    const buyingFlow = await getCurrentUserBuyingFlow(flowId);
    if (
      Array.isArray(buyingFlow?.budgeting?.proof_of_funds_file_ids) &&
      (buyingFlow?.budgeting?.proof_of_funds_file_ids?.length ?? 0) > 0
    ) {
      const selected = files.filter((file) =>
        buyingFlow?.budgeting.proof_of_funds_file_ids.includes(file.id)
      );
      setSelectedFiles(selected);
    } else {
      setSelectedFiles([]);
    }
  };

  /* 
  ================================================================
  FUNCTION: Fetch proof of funds
  ================================================================
  */
  const fetchProofOfFunds = async () => {
    try {
      const files = await getUserProofOfFunds();
      setExpandedFiles(new Array(files.length).fill(false));

      // Look at buying flow data to see which are selected
      if (flowId) {
        const buyingFlow = await getCurrentUserBuyingFlow(flowId);
        if (
          Array.isArray(buyingFlow?.budgeting?.proof_of_funds_file_ids) &&
          (buyingFlow?.budgeting?.proof_of_funds_file_ids?.length ?? 0) > 0
        ) {
          // Filter files based on the ids present in buyingFlow
          const selected = files.filter((file) =>
            buyingFlow?.budgeting.proof_of_funds_file_ids.includes(file.id)
          );
          console.log(selected);
          setSelectedFiles(selected);
        }
      }
      setFiles(files);
    } catch (error) {
      console.error("Error fetching proof of funds data", error);
    }
  };

  /* 
  ================================================================
  FUNC: Handle file deletion
  ================================================================
  */
  const handleFileDeletion = async (fileId: UUIDTypes) => {
    try {
      await deleteUserProofOfFunds(fileId);
    } catch (error) {
      alert(error);
      return;
    }
    fetchProofOfFunds();
  };

  /* 
  ================================================================
  FUNC: Toogle file expansion
  ================================================================
  */
  const toggleFileExpansion = (index: number) => {
    setExpandedFiles((prev) => {
      const newExpandedFiles = [...prev];
      newExpandedFiles[index] = !newExpandedFiles[index];
      return newExpandedFiles;
    });
  };

  return (
    <div
      className={`proof-of-funds-meta-data ${
        files.length === 0
          ? "proof-of-funds-meta-data--not-shown"
          : "proof-of-funds-meta-data--shown "
      }`}
    >
      {files.map((file, index) => (
        <div key={index} className="proof-of-funds-meta-data__container">
          <div className="proof-of-funds-meta-data__header">
            <input
              type="checkbox"
              readOnly
              checked={selectedFiles.some(
                (selectedFile) => selectedFile.id === file.id
              )}
              onClick={() => handleCheckboxChange(file.id)}
            />

            <div
              className="proof-of-funds-meta-data__toggle"
              onClick={() => toggleFileExpansion(index)}
            >
              <img
                src={expandedFiles[index] ? BringUp : DropDown}
                alt="toggle"
              />
            </div>

            <p className="proof-of-funds-meta-data__file-name">
              {file.filename}
            </p>

            <div
              className="proof-of-funds-meta-data__delete"
              onClick={() => handleFileDeletion(file.id)}
            >
              <img src={Trash} alt="delete" />
            </div>
          </div>

          {expandedFiles[index] && (
            <div className="proof-of-funds-meta-data__data-container">
              <div className="proof-of-funds-meta-data__info">
                <p className="proof-of-funds-meta-data__info-key">File Type:</p>
                <p className="proof-of-funds-meta-data__info-value">
                  {file.file_type}
                </p>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default withRequirements(BudgetingProof, [
  userSignedInRequirement,
  userEmailVerifiedRequirement,
  userPhoneVerifiedRequirement,
]);
