import React, { ChangeEvent, useRef, useState } from "react";
import "./common.css";
import { UploadFiles } from "../../../../../APIs/budgeting";

/* 
================================================================
File Upload
================================================================
*/
export enum UploadType {
  PROOF_OF_FUNDS = "proof_of_funds",
  PRE_APPROVAL_DATA = "pre_approval_data",
}

interface FileUploadProps {
  type: UploadType;
  onUploadComplete?: () => void;
}

const FileUpload: React.FC<FileUploadProps> = ({ type, onUploadComplete }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploading, setUploading] = useState<boolean>(false);

  const handleFileUploadClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      const fileArr = Array.from(event.dataTransfer.files);

      try {
        setUploading(true);
        await UploadFiles(fileArr, type);
        setUploading(false);
        if (onUploadComplete) {
          onUploadComplete();
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const updateFile = async (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (event.target.files && event.target.files[0]) {
      const fileArr = Array.from(event.target.files);

      try {
        await UploadFiles(fileArr, type);
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <div className="file-upload">
      <div
        className="file-upload-area"
        style={uploading ? { backgroundColor: "lightgrey" } : {}}
        onDragOver={handleDragOver}
        onDrop={handleFileDrop}
        onClick={handleFileUploadClick}
      >
        <div className="file-upload__text-container">
          {uploading === false ? (
            <p className="file-upload__text">
              <span className="file__upload-text--light">Drop or </span>
              &nbsp;
              <span className="file__upload-text--medium">Browse Files</span>
            </p>
          ) : (
            <p className="file-upload__text">Uploading</p>
          )}

          <p className="file__upload-formats">(.pdf)</p>
        </div>
        <input
          ref={fileInputRef}
          type="file"
          accept="application/pdf"
          style={{ display: "none" }}
          onChange={updateFile}
        />
      </div>
    </div>
  );
};

export { FileUpload };
