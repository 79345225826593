import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import EmailPreview from "./EmailPreview";

import "./Reconnaissance.css";

// Offer and Negotiation Reconnaissance step
const OfferNegotiationReconnaissance: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const flowId = searchParams.get("flow");

  const handleClick = () => {
    // Preserve the flow parameter if it exists
    const flowParam = flowId ? `?flow=${flowId}` : "";
    navigate(`/buyingflow/offer-negotiation/price-determination${flowParam}`);
  };

  // Sample email data
  const emailData = {
    to: "rachel@compass.com",
    from: "kia.ai@agnsy.com",
    subject: "Inquiry for 38 North Almaden Blvd",
    body: [
      "Hi Rachel,",
      "I have a client interested in the 38 North Almaden Blvd property. What is the level of buyers interest on the home?",
    ],
    signature: "Best,\nKia",
  };

  return (
    <div className="reconnaissance">
      {/* Left Side Content */}
      <div className="reconnaissance__left-side">
        {/* Explanation Text */}
        <div className="reconnaissance__explanation">
          <p className="reconnaissance__text">
            I've drafted an email to send over to the listing agent, to gauge
            the level of buyer interest in the home.
          </p>

          <p className="reconnaissance__text">
            Higher interest might mean we need to offer above listing price. Low
            interest means we might be able to get a good deal.
          </p>

          <p className="reconnaissance__text">
            Feel free to send it, or take time to customize or learn more about
            the email.
          </p>

          <p className="reconnaissance__text">
            We should hear back within a day or so.
          </p>
        </div>

        {/* Action Buttons */}
        <div className="reconnaissance__actions">
          <button className="reconnaissance__send-button" onClick={handleClick}>
            Send
          </button>
        </div>
      </div>

      {/* Right Side - Email Section - Not implementing as per instructions */}
      <div className="reconnaissance__right-side">
        <EmailPreview {...emailData} />
      </div>
    </div>
  );
};

export default OfferNegotiationReconnaissance;
