/* React */
import React from "react";

/* Functionality */
import { TOOLS, useToolContext } from "../ToolViewerContext";
import CompResults from "./CompResults";

/* Styling */
import "./ai-comp-sale.css";
import AISparkles from "../../../../../assets/ai_sparkles.svg";
import Map from "../../../../../assets/map.svg";


const AIComparableSale: React.FC = () => {
  /* 
  ================================================================
  CONTEXTS
  ================================================================
  */
  const { isToolOpen, currentTool, optionalContext, closeTool } =
    useToolContext();

  /* 
  ================================================================
  RENDER
  ================================================================
  */
  if (!isToolOpen || currentTool !== TOOLS.COMPARABLE_SALE || !optionalContext?.comparableSales)
    return null;

  return (
    <div className="ai-comparable-sale">
      <div className="ai-comparable-sale__container">
        <div className="ai-comparable-sale__header">
          <img
            className="ai-comparable-sale__sparkle-icon"
            src={AISparkles}
            alt="chat"
          />

          <p className="ai-comparable-sale__title">Comparable Sales Analysis</p>

          <div className="ai-comparable-sale__exit" onClick={closeTool}>
            x
          </div>
        </div>

        <div className="ai-comparable-sale__content">
          <div className="ai-comparable-sale__analysis-container">
            <CompResults comparableSales={optionalContext.comparableSales} />
          </div>

          <div className="ai-comparable-sale__map-container">
            <img className="ai-comparable-sale__map" alt="Map" src={Map} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIComparableSale;
