import React from "react";
import AISparkles from "../../../../../../assets/ai_sparkles.svg";

import "./EmailAIView.css";

interface EmailAIViewProps {
  isOpen: boolean;
  onClose: () => void;
  emailData: {
    to: string;
    from: string;
    subject: string;
    body: string[];
    signature: string;
  };
}

const EmailAIView: React.FC<EmailAIViewProps> = ({
  isOpen,
  onClose,
  emailData,
}) => {
  if (!isOpen) return null;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { to, from, subject, body, signature } = emailData;

  return (
    <div className="email-view-tool">
      <div className="email-view-tool__content">
        <div className="email-view-tool__header">
          <div className="email-view-tool__title">
            <img
              className="email-view-tool__icon"
              src={AISparkles}
              alt="ai_sparkles"
            />
            <h2>Email</h2>
          </div>
          <button
            className="email-view-tool__request-btn"
            onClick={() => {
              /* Implement request human review logic */
            }}
          >
            Request Human Review
          </button>
          <button className="email-view-tool__close-btn" onClick={onClose}>
            ×
          </button>
        </div>

        <div className="email-view-tool__email-body">
          <div className="email-view-tool__email-editor">
            <div className="email-view-tool__field">
              <label>To:</label>
              <input type="text" defaultValue={to} />
            </div>
            <div className="email-view-tool__field">
              <label>From:</label>
              <input type="text" defaultValue={from} />
            </div>
            <div className="email-view-tool__field">
              <label>Subject:</label>
              <input type="text" defaultValue={subject} />
            </div>
            <div className="email-view-tool__body-container">
              <textarea
                defaultValue={body.join("\n\n")}
                rows={10}
                className="email-view-tool__body"
              />
            </div>
          </div>
        </div>
        <div className="email-view-tool__actions">
          <button className="email-view-tool__action-btn">Save Changes</button>
        </div>
      </div>
    </div>
  );
};

export default EmailAIView;
