import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import "./ai-comp-sale.css";
import { ComparableSales } from "../../../../../model/Core/ComparableSales";

interface CompResultsProps {
  comparableSales: ComparableSales;
}

const CompResults: React.FC<CompResultsProps> = ({ comparableSales }) => {
  const markdownContent =
    comparableSales?.analysis.result || "No analysis available";

  return (
    <div className="comp-results">
      <div className="comp-results-content">
        <ReactMarkdown remarkPlugins={[remarkGfm]}>
          {markdownContent}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export default CompResults;
