import {
  signInWithEmailAndPassword,
  signInWithCustomToken,
  signOut,
  isSignInWithEmailLink,
  signInWithEmailLink,
  UserCredential,
  updatePassword,
  deleteUser,
  sendPasswordResetEmail,
  confirmPasswordReset,
  sendEmailVerification,
} from "firebase/auth";

import { User as UserDataModel } from "../model/Core/User";

import { User, getAuth } from "firebase/auth";

import { firebaseApp } from "../firebase/firebase";
import { getWithAuth, postWithAuth } from "../firebase/authentication";
import { PriceDetermination } from "../model/Core/AI";
import { Home } from "../model/Core/Home";
import { UUIDTypes } from "uuid";

const Auth = getAuth(firebaseApp);

const AGNSY_API_BASE_URL = process.env.REACT_APP_AGNSY_SERVER_ADDRESS;
const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

/*
  ================================
  EMAIL VERIFICATION Settings
  ================================
  */
const actionCodeSettings = {
  url: `${window.location.origin}/disclosure`,
  handleCodeInApp: true,
};
const actionCodeSettingsForgotPassword = {
  url: `${window.location.origin}/login`,
  handleCodeInApp: true,
};

/*
  ================================
  SIGN UP
  ================================
  */
const signUpEmailVerification = (user: User): Promise<void> => {
  return new Promise((resolve, reject) => {
    sendEmailVerification(user, actionCodeSettings)
      .then(() => {
        // Save form data locally
        window.localStorage.setItem("emailForSignUp", user.email ?? "");

        resolve();
      })
      .catch((error) => {
        console.error("Error sending email verification link:", error);
        reject(error);
      });
  });
};

const ForgotPasswordEmailVerification = (email: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    sendPasswordResetEmail(Auth, email, actionCodeSettingsForgotPassword)
      .then(() => {
        // Save form data locally
        window.localStorage.setItem("emailForPasswordReset", email);

        resolve();
      })
      .catch((error) => {
        console.error("Error sending email verification link:", error);
        reject(error);
      });
  });
};

const resetPassword = (code: string, newPassword: string) => {
  return confirmPasswordReset(Auth, code, newPassword);
};

const isValidRegistrationLink = (url: string) => {
  return isSignInWithEmailLink(Auth, url);
};

const matchingEmailAndLink = (
  email: string,
  url: string
): Promise<UserCredential> => {
  return signInWithEmailLink(Auth, email, url);
};

/* 
  ================================
  LOGIN
  ================================
  */
const logInEmailPassword = (
  email: string,
  password: string
): Promise<UserCredential> => {
  return signInWithEmailAndPassword(Auth, email, password);
};

async function logInCustomToken(token: string): Promise<UserCredential> {
  return await signInWithCustomToken(Auth, token);
}

/*
  ================================
  LOGOUT
  ================================
  */
const logoutCurrentUser = (): Promise<void> => {
  return signOut(Auth)
    .then(() => {
      console.info("Successfuly signed out current user.");
    })
    .catch((error) => {
      console.error("Error signing out current user: ", error);
    });
};

export {
  signUpEmailVerification,
  ForgotPasswordEmailVerification,
  logInEmailPassword,
  logInCustomToken,
  logoutCurrentUser,
  isValidRegistrationLink,
  matchingEmailAndLink,
  updatePassword,
  deleteUser,
  confirmPasswordReset,
  resetPassword,
};

export enum AuthStatusCode {
  OK = 200,
  CREATED = 201,
  BAD_REQUEST = 400,
  CONFLICT = 409,
  SERVER_ERROR = 500,
}

/**
 * Enum for the various status codes returned during the signup process.
 *
 * @enum {number}
 * @property {number} CREATED - User successfully created (HTTP 201).
 * @property {number} CONFLICT_USERNAME - Username already in use (HTTP 409).
 * @property {number} CONFLICT_PHONE_NUMBER - Phone verified with another user (HTTP 409).
 * @property {number} SERVER_ERROR - Server error occurred (HTTP 500).
 * @property {number} UNEXPECTED_ERROR - An unexpected error occurred (Custom 600).
 *
 * Note: The backend returns 409 for both user and phone number conflicts.
 * Custom codes 409001 and 409002 are used to handle these specific cases gracefully.
 */
export enum SignupStatusCodes {
  CREATED = 201,
  CONFLICT_PHONE_NUMBER = 409001,
  CONFLICT_USERNAME = 409002,
  SERVER_ERROR = 500,
  UNEXPECTED_ERROR = 600,
}

/**
 * Enum for the various status codes returned during the login process.
 *
 * @enum {number}
 * @property {number} SUCCESS - Login token retrieved successfully (HTTP 200).
 * @property {number} INVALID_CREDENTIALS - Invalid username/password combination (HTTP 400).
 * @property {number} SERVER_ERROR - Server error occurred (HTTP 500).
 * @property {number} UNEXPECTED_ERROR - An unexpected error occurred (Custom 600).
 *
 * Note: Custom error codes are used for clarity and to differentiate between
 * standard HTTP errors and application-specific errors.
 */
export enum LoginStatusCodes {
  SUCCESS = 200,
  INVALID_CREDENTIALS = 400,
  SERVER_ERROR = 500,
  UNEXPECTED_ERROR = 600,
}

// ============================
// Common Account Flow Functions
// ============================

/**
 * Sends a signup request to the server and handles the response.
 *
 * @param {FormData} formData - The data for the new user account.
 * @returns {Promise<SignupStatusCodes>} - The status code indicating the result of the signup process.
 */
export async function UserSignup(
  formData: FormData
): Promise<SignupStatusCodes> {
  // Configuration options for the fetch request
  const requestOptions = {
    method: "POST",
    body: formData,
  };

  try {
    console.log("Sending signup request..."); // Log request
    // send the signup request to the API
    const response = await fetch(
      `${HTTP_PROTOCAL}://${AGNSY_API_BASE_URL}/api/user/auth/signup`,
      requestOptions
    );

    if (response.ok) {
      console.log("Signup successful!");
      return SignupStatusCodes.CREATED;
    }

    const errorData = await response.json();

    if (response.status === AuthStatusCode.CONFLICT) {
      // Handle specific conflict errors based on response message
      const detail = errorData.detail?.trim().toLowerCase();

      // Username conflict
      if (detail?.includes("username")) {
        console.log("Username conflict");
        return SignupStatusCodes.CONFLICT_USERNAME;
      }

      // Phone number conflict
      if (detail?.includes("phone")) {
        console.log("Phone number conflict");
        return SignupStatusCodes.CONFLICT_PHONE_NUMBER;
      }
    }

    if (response.status === AuthStatusCode.SERVER_ERROR) {
      console.log("Server error occured during signup.");
      return SignupStatusCodes.SERVER_ERROR;
    }
  } catch (error) {
    // Catch any unexpected errors during request
    console.error("Error during signup:", error);
    return SignupStatusCodes.UNEXPECTED_ERROR;
  }
  // Fallback for any unhandled errors
  return SignupStatusCodes.UNEXPECTED_ERROR;
}

/**
 * Sends a login request to the server and processes the response.
 *
 * @param {FormData} formData - The data for user login.
 * @returns {Promise<LoginStatusCodes>} - The status code indicating the result of the login process.
 */
export async function UserLogin(formData: FormData): Promise<LoginStatusCodes> {
  // Configuration options for the fetch request
  const requestOptions = {
    method: "POST",
    body: formData,
  };

  try {
    console.log("Sending login request...");
    // send the login request to the API
    const response = await fetch(
      `${HTTP_PROTOCAL}://${AGNSY_API_BASE_URL}/api/user/auth/login/request-login-token`,
      requestOptions
    );

    if (response.ok) {
      // Parse response to extract token
      const tokenData = await response.json();
      const customToken = tokenData.token;

      // Use the custom token to sign in
      await logInCustomToken(customToken);

      console.log("Login successful!");
      return LoginStatusCodes.SUCCESS;
    }

    // Handle for invalid credentials
    if (response.status === AuthStatusCode.BAD_REQUEST) {
      console.log("Invalid credentials provided.");
      return LoginStatusCodes.INVALID_CREDENTIALS;
    }
  } catch (error) {
    // Catch any unexpected errors during request
    console.error("Error during token retrievel: ", error);
    return LoginStatusCodes.UNEXPECTED_ERROR;
  }
  // Fallback for any unhandled errors
  return LoginStatusCodes.UNEXPECTED_ERROR;
}

export enum SignupMessage {
  CREATED = "Successfully created user.",
  CONFLICT_PHONE_NUMBER = "Phone verified with another user already.",
  CONFLICT_USERNAME = "Username already in use",
  SERVER_ERROR = "Server error. Please try again later.",
  UNEXPECTED_ERROR = "An unexpected error occurred. Please try again.",
}

export enum LoginMessage {
  SUCCESS = "Successfully sent login token",
  INVALID_CREDENTIALS = "Invalid username or password.",
  SERVER_ERROR = "Server error. Please try again later.",
  UNEXPECTED_ERROR = "An unexpected error occurred. Please try again.",
}

export const getSignupErrorMessage = (status: SignupStatusCodes) => {
  switch (status) {
    case SignupStatusCodes.CONFLICT_USERNAME:
      return SignupMessage.CONFLICT_USERNAME;
    case SignupStatusCodes.CONFLICT_PHONE_NUMBER:
      return SignupMessage.CONFLICT_PHONE_NUMBER;
    case SignupStatusCodes.SERVER_ERROR:
      return SignupMessage.SERVER_ERROR;
    case SignupStatusCodes.UNEXPECTED_ERROR:
    default:
      return SignupMessage.UNEXPECTED_ERROR;
  }
};

export const getLoginErrorMessage = (status: LoginStatusCodes) => {
  switch (status) {
    case LoginStatusCodes.INVALID_CREDENTIALS:
      return LoginMessage.INVALID_CREDENTIALS;
    case LoginStatusCodes.SERVER_ERROR:
      return LoginMessage.SERVER_ERROR;
    case LoginStatusCodes.UNEXPECTED_ERROR:
    default:
      return LoginMessage.UNEXPECTED_ERROR;
  }
};

export interface UserPhone {
  number: string;
  verified: boolean;
}

export const fetchCurrentUserData = async (): Promise<UserDataModel | null> => {
  try {
    const response = await getWithAuth(
      `${HTTP_PROTOCAL}://${AGNSY_API_BASE_URL}/api/user/data`
    );
    if (response.ok) {
      const userData = await response.json();
      return userData as UserDataModel;
    }
  } catch (error) {
    console.error("Error retrieving user data:", error);
  }
  return null;
};

export const fetchHomeData = async (
  homeId: UUIDTypes
): Promise<Home | null> => {
  try {
    const response = await getWithAuth(
      `${HTTP_PROTOCAL}://${AGNSY_API_BASE_URL}/api/homes/${homeId}`
    );

    if (response.ok) {
      const homeData = await response.json();
      return homeData as Home;
    }
  } catch (error) {
    console.error("Error retrieving home data:", error);
  }
  return null;
};

export const fetchHomeCoverPhoto = async (
  homeId: UUIDTypes
): Promise<string | null> => {
  try {
    const response = await getWithAuth(
      `${HTTP_PROTOCAL}://${AGNSY_API_BASE_URL}/api/homes/${homeId}/cover-photo`
    );

    if (response.ok) {
      const base64Data = await response.text();
      const cleanBase64 = base64Data.replace(/^["']|["']$/g, "");
      return cleanBase64;
    }
  } catch (error) {
    console.error("Error retrieving home cover photo:", error);
  }
  return null;
};

export const fetchPriceDetermination = async (
  flowId: UUIDTypes
): Promise<PriceDetermination | null> => {
  try {
    const response = await getWithAuth(
      `${HTTP_PROTOCAL}://${AGNSY_API_BASE_URL}/api/buying-flow/offer-negotiation/price-suggestions/${flowId}`
    );

    if (response.ok) {
      const priceDetermination = await response.json();
      return priceDetermination as PriceDetermination;
    }
  } catch (error) {
    console.error("Error retrieving price determination:", error);
  }
  return null;
};

export const setPriceInFlow = async (
  flowId: UUIDTypes,
  price: number
): Promise<void> => {
  try {
    const formData = new FormData();
    formData.append("buying_flow_id", flowId as string);
    formData.append("price", price.toString());
    await postWithAuth(
      `${HTTP_PROTOCAL}://${AGNSY_API_BASE_URL}/api/buying-flow/offer-negotiation/choose-price`,
      formData
    );
  } catch (error) {
    console.error("Error setting price:", error);
  }
};
