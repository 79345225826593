/* React */
import { useCallback, useMemo } from "react";

/* Data Models */
import { BuyingFlow } from "../../../../../model/Core/BuyingFlow";
import { Home } from "../../../../../model/Core/Home";
import { User } from "../../../../../model/Core/User";

/* APIs */
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

export const FoundHome: React.FC<{
  buyingFlow: BuyingFlow;
  userData: User;
  home: Home;
}> = ({ home }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "", // Use environment variable
  });

  const containerStyle = {
    width: "100%",
    height: "200px",
    borderRadius: "10px",
  };

  const mapOptions = {
    disableDefaultUI: true, // Disable all default UI controls
    mapTypeControl: false, // Disable map type control
    streetViewControl: false, // Disable street view control
    rotateControl: false, // Disable rotate control
    fullscreenControl: false, // Disable fullscreen control
  };

  const center = useMemo(() => {
    if (home && home.coordinates) {
      const [latitude, longitude] = home.coordinates;
      return { lat: latitude, lng: longitude };
    }
    return { lat: 0, lng: 0 }; // Default center if no coordinates
  }, [home]);

  const onLoad = useCallback(() => {}, [center]);
  const onUnmount = useCallback(() => {}, []);

  if (!isLoaded) {
    return <div>Loading Map...</div>;
  }

  if (!home || !home.coordinates) {
    return <div>Home coordinates not available.</div>;
  }

  return (
    <div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={mapOptions}
      >
        <Marker position={center} />
      </GoogleMap>
    </div>
  );
};
