/* React */
import React from "react";

/* Functionality */
import { TOOLS, useToolContext } from "../ToolViewerContext";
import AnalysisResults from "./AnalysisResults";

/* Styling */
import "./ai-disclosure-analysis.css";
import AISparkles from "../../../../../assets/ai_sparkles.svg";


const AIDisclosureAnalysis: React.FC = () => {
  /* 
  ================================================================
  CONTEXTS
  ================================================================
  */
  const { isToolOpen, currentTool, optionalContext, closeTool } =
    useToolContext();

  /* 
  ================================================================
  RENDER
  ================================================================
  */
  if (
    !isToolOpen ||
    currentTool !== TOOLS.DISCLOSURE_ANALYSIS ||
    !optionalContext
  )
    return null;

  return (
    <div className="ai-disclosure-analysis">
      <div className="ai-disclosure-analysis__container">
        <div className="ai-disclosure-analysis__header">
          <img
            className="ai-disclosure-analysis__sparkle-icon"
            src={AISparkles}
            alt="chat"
          />

          <p className="ai-disclosure-analysis__title">Disclosure Analysis</p>

          <div className="ai-disclosure-analysis__exit" onClick={closeTool}>
            x
          </div>
        </div>

        <div className="ai-disclosure-analysis__content">
          <div className="ai-disclosure-analysis__analysis-container">
            <AnalysisResults
              analysisResults={optionalContext.analysisResults}
            />
          </div>

          <div className="ai-disclosure-analysis__pdf-container">
            {/* TODO: Add */}
            hi
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIDisclosureAnalysis;
