import React, { useState } from "react";
import "./cash.css";
import { useNavigateWithFlowIdURLParam } from "../../../BuyingFlow";

const BudgetingEnterCash: React.FC = () => {
  const navigateWithFlow = useNavigateWithFlowIdURLParam();
  const [amount, setAmount] = useState<string>("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    // Remove non-numeric characters
    const numericValue = value.replace(/[^0-9]/g, "");

    if (numericValue === "") {
      setAmount("");
      return;
    }

    // Format the number with commas
    const formattedValue = new Intl.NumberFormat().format(Number(numericValue));
    setAmount(formattedValue);
  };

  const isAmountValid = () => {
    return amount.trim() !== "" && !isNaN(Number(amount.replace(/,/g, "")));
  };

  const handleLenderClick = () => {
    navigateWithFlow("/buyingflow/budgeting/lender");
  };

  return (
    <div className="enter-cash">
      {/* Introduction Section */}
      <section className="enter-cash__intro">
        <p className="enter-cash__description">
          Planning to buy with all enter-cash?
        </p>
      </section>

      {/* Input Section */}
      <section className="enter-cash__input-section">
        <div className="enter-cash__input-container">
          <span className="enter-cash__input-icon">$</span>
          <input
            type="text"
            className="enter-cash__input"
            placeholder="Enter amount willing to spend"
            value={amount}
            onChange={handleChange}
          />
        </div>
      </section>

      {/* Call to Action */}
      <section className="enter-cash__cta">
        <button className="enter-cash__cta-button" disabled={!isAmountValid()}>
          Continue
        </button>
      </section>

      {/* Help Text */}
      <section className="enter-cash__help">
        <div className="enter-cash__help-text" onClick={handleLenderClick}>
          Need help paying? Click here to continue.
        </div>
      </section>
    </div>
  );
};

export default BudgetingEnterCash;
